import React, { useContext, useReducer } from "react";
import AddressContext from "../address/address-context";
import PaymentContext from "../payment/payment-context";
import { ADD_ITEM, CANCEL_ORDER, RETURN_ORDER, SET_ITEMS, TOGGLE_CHECK } from "./order-actions";
import OrderContext from "./order-context";
import OrderReducer from "./order-reducer";
import api from "../../api";
function OrderState({ children }) {
  const initialState = {
    items: [],
  };

  const { selectedAddressId} = useContext(AddressContext);
  const { checkoutSelectedMethodId } = useContext(PaymentContext);
  const [state, dispatch] = useReducer(OrderReducer, initialState);

  async function addOrderItem(order) {
    // add address and payment to order
    order.addressId = selectedAddressId;
    order.paymentId = checkoutSelectedMethodId;
    const result = await api.post("/order", order);
    dispatch({ type: ADD_ITEM, payload: result.data });
    return result.data;
  }

  async function fetchOrders() {
    const result = await api.get("/order");
    dispatch({ type: SET_ITEMS, payload: result.data });
  }
  async function cancelOrder(id) {
    try {
      const result = await api.post(`/order/cancel/${id}`);
      dispatch({type: CANCEL_ORDER, payload: id});
    } catch (error) {
      
    }
  }
  function getOrderById(id) {
    return state.items.find((item) => item.id === +id);
  }
  async function buyAgain(id){
    try{
      const result = await api.post(`/order/buy-again/${id}`,{
        addressId: selectedAddressId,
        paymentId: checkoutSelectedMethodId
      });
      dispatch({ type: ADD_ITEM, payload: result.data });
      return result.data;
    }catch(error){
      console.log(error)
      throw error;
    }
  }
  function toggleCheck(order,product){
    dispatch({
      type: TOGGLE_CHECK,
      payload: {order, product},
    });
  };

  async function returnOrder(order, {reason , dropOff}){
    try {
      const productIds = order.products.filter(item=>item.checked).map(item=>item.id);
      const result = await api.post(`/order/return/${order.id}`,{
        productIds,
        reason,
        dropOff
      });
      
      dispatch({
        type: RETURN_ORDER,
        payload: order
      });

      return result.data;
      
    } catch (error) {
      console.log(error);
      throw error;
    }

  }

  async function addReview(review){
      try {
          const result = await api.post("/review",review);
          
      } catch (error) {
        console.log(error)
        throw error;
      }
  }

  return (
    <OrderContext.Provider
      value={{
        orderItems: state.items,
        addOrderItem,
        fetchOrders,
        getOrderById,
        cancelOrder,
        buyAgain,
        toggleCheck,
        returnOrder,
        addReview
      }}
    >
      {children}
    </OrderContext.Provider>
  );
}

export default OrderState;
