import React, { useContext, useRef } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import AuthContext from "../../../contexts/shared/auth/auth-context";
import Label from "../../common/Label";
import ChangePassword from "./ChangePassword";
import { nameValidation, phoneNumberValidation } from "../../../validations";

const schema = Yup.object().shape({
  name: Yup.string().required("Name is a required field").matches(nameValidation.regex,nameValidation.message),
  email: Yup.string().required("Email is a required field").email("Invalid email"),
  phoneNumber: Yup.string().matches(
    phoneNumberValidation.regex,
    phoneNumberValidation.message
  ),
});

function PersonalInformation() {
  const { user, updateInformation } = useContext(AuthContext);
  const formikRef = useRef();

  const initialValues = {
    name: user?.name,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
  };
  async function formSubmitted(values) {
    try {
      await updateInformation(values);
      toast.success("Personal Information Updated Successfully", {
        position: "bottom-right",
        draggable: true,
      });
    } catch (error) {
      formikRef.current.resetForm();
      toast.error(error.response.data.message, {
        position: "bottom-right",
        draggable: true,
      });
    }
  }
  function onBlur() {
    // this function does nothing
    // i just don't want formik to validate onBlur
    // but validateOnBlur is not working so i am setting this function to every input
    // onBlur causes onClick to not be called
  }
  return (
    <div className="account__information" id="accountInformation">
      <div className="account__information-detail-wrapper">
        <div className="account__information-detail">
          <img
            src="/images/profile.png"
            id="profileImage"
            className="account__information-profile-image"
            alt="profile pic"
          />
          <h1 className="account__information-username">{user?.name}</h1>
          <h1 className="account__information-email">{user?.email}</h1>
        </div>
      </div>
      {/* Form */}
      <div className="account__personal">
        <h1 className="account__personal-title">Your Profile</h1>
        <div className="account__personal-grid">
          <div className="account__personal-edit">
            <Formik
              innerRef={formikRef}
              enableReinitialize
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={formSubmitted}
            >
              {({ values, setFieldValue, isSubmitting, isValid, dirty, status, resetForm }) => (
                <Form className="account__form" id="personalInformationForm">
                  {/* Name*/}
                  <div className="account__personal-input-wrapper">
                    <Label title="Name" className="account__personal-label" />
                    <Field id="nameInput" type="string" name="name" className="account__input" onBlur={onBlur} />
                    <ErrorMessage id="nameError" name="name" component="div" className="error" />
                  </div>

                  {/* Phone Number*/}
                  <div className="account__personal-input-wrapper">
                    <Label title="Phone Number" className="account__personal-label" />
                    <Field
                      type="string"
                      id="personalPhoneInput"
                      name="phoneNumber"
                      className="account__input"
                      onBlur={onBlur}
                    />
                    <ErrorMessage id="phoneNumberError" name="phoneNumber" component="div" className="error" />
                  </div>
                  {/* Email*/}
                  <div className="account__personal-input-wrapper">
                    <Label title="email" className="account__personal-label" />
                    <Field type="string" id="emailInput" name="email" className="account__input" onBlur={onBlur} />
                    <ErrorMessage id="emailError" name="email" component="div" className="error" />
                  </div>
                  {/* Submit Button */}
                  <div className="account__btn-wrapper">
                    <button id="personalUpdateBtn" type="submit" className={`account__btn-submit`} disabled={!dirty}>
                      Update
                    </button>
                    <button
                      id="personalCancelBtn"
                      onClick={resetForm}
                      type="button"
                      className={`account__btn-cancel ${!dirty && "hidden"}`}
                    >
                      Cancel
                    </button>
                  </div>
                  {status && <div className="error">{status}</div>}
                </Form>
              )}
            </Formik>
          </div>
          <div className="account__credentials">
            <ChangePassword />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInformation;
