import React from "react";

import Title from "./Title";
function Alert() {
  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Radio Button" id="title" />

      <form>
        <div>
          <p className="py-2">Please select your favorite Web language:</p>
          <div className="flex gap-3">
            <input type="radio" id="html" name="fav_language" value="HTML" />
            <label for="html">HTML</label>
          </div>
          <div className="flex gap-3">
            <input type="radio" id="css" name="fav_language" value="CSS" />
            <label for="css">CSS</label>
          </div>
          <div className="flex gap-3">
            <input type="radio" id="javascript" name="fav_language" value="JavaScript" />
            <label for="javascript">JavaScript</label>
          </div>
        </div>
        <br />
        <div>
          <p className="py-2">please choose your favorite contact method</p>
          <div className="flex gap-3">
            <input type="radio" id="email" name="contact" value="email" />
            <label for="email">email</label>
          </div>
          <div className="flex gap-3">
            <input type="radio" id="phone" name="contact" value="phone" />
            <label for="phone">phone</label>
          </div>
          <div className="flex gap-3">
            <input type="radio" id="mail" name="contact" value="mail" />
            <label for="mail">Mail</label>
          </div>
        </div>
        <br />
        <div>
          <p className="py-2">Select T-shirt size</p>
          <div className="flex gap-3">
            <input type="radio" id="small" name="tshirt" value="sm" />
            <label for="small">Small</label>
          </div>
          <div className="flex gap-3">
            <input type="radio" id="medium" name="tshirt" value="md" />
            <label for="medium">Medium</label>
          </div>
          <div className="flex gap-3">
            <input type="radio" id="large" name="tshirt" value="lg" />
            <label for="large">Large</label>
          </div>
          <div className="flex gap-3">
            <input type="radio" id="extraLarge" name="tshirt" value="xl" />
            <label for="extra large">Extra Large</label>
          </div>
          <div className="flex gap-3">
            <input type="radio" id="extraExtraLarge" name="tshirt" value="xxl" />
            <label for="extraExtraLarge">Extra Extra large</label>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Alert;
