import React, { useContext } from "react";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { NavLink, useNavigate } from "react-router-dom";

import Search from "./Search";
import { Roles } from "../../../constants";
import AuthContext from "../../../contexts/shared/auth/auth-context";
import CartContext from "../../../contexts/customer/cart/cart-context";

function TopNav() {
  const { user, setUser } = useContext(AuthContext);
  const { totalQuantity } = useContext(CartContext);
  const navigate = useNavigate();
  function logout() {
    localStorage.removeItem("user");
    setUser(null);
  }

  function cartClicked() {
    if (user && user.role === Roles.Customer) navigate("shopping-cart");
  }

  return (
    <div className="top-nav">
      <NavLink className="top-nav__logo" to={"/"}>
        TEKSCHOOL
      </NavLink>

      {/* Search */}
      <Search />

      {/* Help And Settings */}
      {user && (
        <>
          <div>
            <NavLink className="top-nav__btn" to={"/profile"} id="accountLink">
              Account
            </NavLink>
          </div>
          <div>
            <NavLink className="top-nav__btn" to={"/order"} id="orderLink">
              Orders
            </NavLink>
          </div>
        </>
      )}

      {/* Sign in */}
      {!user && (
        <div>
          <NavLink className="top-nav__btn" to={"/auth/login"} id="signinLink">
            Sign in
          </NavLink>
        </div>
      )}
      {user && (
        <div>
          <button className="top-nav__btn" onClick={logout} id="logoutBtn">
            Log out
          </button>
        </div>
      )}

      {/* Cart */}
      <div className="top-nav__btn top-nav__cart" onClick={cartClicked} id="cartBtn">
        <HiOutlineShoppingCart />
        <p>Cart </p>
        {totalQuantity !== 0 && (
          <span className="top-nav__cart-quantity" id="cartQuantity">
            {totalQuantity}
          </span>
        )}
      </div>
    </div>
  );
}

export default TopNav;
