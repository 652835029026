import React, { useContext } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import Label from "../../common/Label";
import PaymentContext from "../../../contexts/customer/payment/payment-context";
import { nameValidation } from "../../../validations";
const paymentSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .required("Card Number is a required field")
    .min(16)
    .max(16)
    .matches(/^[0-9]*[0-9]$/, "Only numbers are allowed"),
  nameOnCard: Yup.string()
    .required("Name On Card is a required field")
    .matches(nameValidation.regex, nameValidation.message),
  expirationMonth: Yup.number().required(),
  expirationYear: Yup.number().required(),
  securityCode: Yup.string()
    .min(3)
    .max(3)
    .required("Security Code is a required field")
    .matches(/^[0-9]*[0-9]$/, "Only numbers are allowed"),
});

function NewPayment({ setShow, payment, edit = false }) {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const years = new Array(20).fill(0).map((item, index) => currentYear + index);

  const { addPaymentMethod, updatePaymentMethod } = useContext(PaymentContext);

  let initialValues = {
    cardNumber: "",
    nameOnCard: "",
    expirationMonth: currentMonth+1,
    expirationYear: currentYear,
    securityCode: "",
  };

  if (edit) initialValues = payment;

  function getMonths(year){
    // for current year only show valid months (latest month)
    if(+year===currentYear){
      return new Array(12-currentMonth).fill(0).map((item,index)=>currentMonth+index +1);
    }
    return new Array(12).fill(0).map((item, index) => index + 1);
  }

  async function formSubmitted(values, { setSubmitting, setStatus, resetForm }) {
    let message = "Payment Method added sucessfully";
    try {
      setSubmitting(true);
      values.expirationMonth = +values.expirationMonth;
      values.expirationYear = +values.expirationYear;
      if (edit) {
        await updatePaymentMethod(values);
        message = "Payment Method updated Successfully";
      } else {
        await addPaymentMethod(values);
      }
      toast.success(message, {
        position: "bottom-right",
        draggable: true,
      });
      resetForm();
      setShow && setShow(false);
    } catch (error) {
      
    } finally {
      setSubmitting(false);
    }
  }
  function onBlur() {
    // this function does nothing
    // i just don't want formik to validate onBlur
    // but validateOnBlur is not working so i am setting this function to every input
    // onBlur causes onClick to not be called
  }
  return (
    <div className="account__payment-new">
      <div className="account__payment-new-wrapper">
        <h1 className="account__payment-new-title">{edit ? "Update" : "Add"} Debit or Credit Cart</h1>

        <Formik initialValues={initialValues} validationSchema={paymentSchema} onSubmit={formSubmitted}>
          {({ isSubmitting, isValid, dirty, status, resetForm ,values}) => (
            <Form className="account__form" id="newPaymentForm">
              {/* Card Number */}
              <div className="account__payment-new-container">
                <Label title="Card Number" className="account__payment-new-label" />
                <div className="w-7/12">
                  <Field
                    type="string"
                    name="cardNumber"
                    className="account__payment-new-input"
                    id="cardNumberInput"
                    onBlur={onBlur}
                  />
                  <ErrorMessage name="cardNumber" id="cardNumberError" component="div" className="error" />
                </div>
              </div>
              {/* Name on Card */}
              <div className="flex gap-2 items-center py-2">
                <Label title="Name on Card" className=" w-5/12 text-right text-sm" />
                <div className="w-7/12">
                  <Field
                    type="string"
                    name="nameOnCard"
                    className="account__payment-new-input"
                    id="nameOnCardInput"
                    onBlur={onBlur}
                  />
                  <ErrorMessage name="nameOnCard" id="nameOnCardError" component="div" className="error" />
                </div>
              </div>
              {/* Expiratin Date */}
              <div className="flex gap-2 items-center py-2">
                <Label title="Expiration Date" className=" w-5/12 text-right text-sm" />
                <div className="w-7/12 flex gap-2">
                  <Field
                    as="select"
                    name="expirationMonth"
                    onBlur={onBlur}
                    id="expirationMonthInput"
                    className="account__payment-new-select"
                  >
                    {getMonths(values.expirationYear).map((item, index) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </Field>
                  <Field
                    as="select"
                    name="expirationYear"
                    id="expirationYearInput"
                    onBlur={onBlur}
                    className="account__payment-new-select"
                  >
                    {years.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>

              {/* Security Code */}
              <div className="account__payment-security">
                <label className="account__payment-security-label">
                  <span>Security Code</span>
                  <span>(CCV/CVC)</span>
                </label>
                <div className="account__payment-security-wrapper">
                  <Field
                    type="string"
                    name="securityCode"
                    id="securityCodeInput"
                    onBlur={onBlur}
                    className="account__payment-new-input"
                  />
                  <ErrorMessage name="securityCode" id="securityCodeError" component="div" className="error" />
                </div>
              </div>
              {/* Submit Button */}
              <div className="account__btn-wrapper">
                <button type="submit" className={`account__btn-submit`} id="paymentSubmitBtn">
                  {edit ? "Update Your Card" : "Add Your card"}
                </button>
                <button
                  id="paymentCancelBtn"
                  type="button"
                  onClick={resetForm}
                  className={`account__btn-cancel ${!dirty && "hidden"}`}
                >
                  Cancel
                </button>
              </div>
              {status && <div className="error">{status}</div>}
            </Form>
          )}
        </Formik>
      </div>
      <div className="account__payment-ad">
        <p className="account__payment-ad-text">TekSchool accepts most major credit and debit cards:</p>
        <div>
          <img src="/images/credit_cards.png" alt="credit cards" />
        </div>
      </div>
    </div>
  );
}

export default NewPayment;
