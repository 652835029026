export function getUniqueId() {
    return String(Date.now().toString(32) + Math.random().toString(16)).replace(
      /\./g,
      ""
    );
}

// #Ref: https://stackoverflow.com/questions/17781472/how-to-get-a-subset-of-a-javascript-objects-properties
// estus flask answer
export const pick = (obj, ...keys) => Object.fromEntries(
  keys
  .filter(key => key in obj)
  .map(key => [key, obj[key]])
);

export const omit = (obj, ...keys) => Object.fromEntries(
  Object.entries(obj)
  .filter(([key]) => !keys.includes(key))
);