import React, { useEffect, useState } from "react";

import HomeContext from "./home-context";
import api from "../../api";
function AuthState({ children }) {
  const [homeData, setHomeData] = useState(null);
 
  async function fetchHomeData() {
    try {
      const result = await api.get("/home");
      setHomeData(result.data);
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
    <HomeContext.Provider value={{ homeData, fetchHomeData }}>
      {children}
    </HomeContext.Provider>
  );
}

export default AuthState;
