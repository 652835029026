import React, { useContext } from "react";
import OrderContext from "../../../contexts/customer/order/order-context";
import Order from "./Order";

function Index() {
  const { orderItems } = useContext(OrderContext);

  return (
    <div className="orders">
      <div className="orders__container">
        <h1 className="orders__title">Your Orders</h1>
        <p className="orders__count-container">
          <span className="orders__count">{orderItems.length} Orders</span> Placed
        </p>
        {orderItems && orderItems.map((item,index) => <Order key={item.id} order={item} index={index} />)}
      </div>
    </div>
  );
}

export default Index;
