import React from "react";
import Category from "./Category";

function Categories({items}) {

  return (
    <div className="home__categories-grid">
      {items && Object.keys(items).map((key) => (
        <Category category={items[key]} key={key} />
      ))}
    </div>
  );
}

export default Categories;
