import React from "react";
function Dropdown({ items, select , itemClass = ''}) {
  return (
    <div>
      <select
        className="search__select"
        onChange={(e) => select(e.target.value)}
        id="search"
      >
        {items.map((item) => (
          <option key={item.id} value={item.id} className={`search__select-option ${itemClass}`}>
            {item.title}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Dropdown;
