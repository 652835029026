import React, { useEffect, useState } from "react";
import Title from "./Title";

function KeyPress() {
  const [key, setKey] = useState(null);
  useEffect(() => {
    function keyPress(event) {
      setKey(event.key);
    }
    document.addEventListener("keyup", keyPress);
    return () => document.removeEventListener("keyup", keyPress);
  }, []);

  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Key Press" id="title" />
      <p>Press any key </p>
      {key && <p className="text-green-800">You pressed: {key}</p>}
    </div>
  );
}

export default KeyPress;
