import React, { useEffect, useRef, useState } from "react";
import api from "../../../contexts/api";
import Loading from "./Loading";
import SelectItem from "./SelectItem";

let timeout = null;

function DynamicSelect({ label, placeholder }) {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  // Outside click functionality
  const dropdownRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  function handleClickOutside(e) {
    if (!dropdownRef?.current?.contains(e.target)) {
      setItems([]);
    }
  }

  function change(e) {
    const localSearchTerm = e.target.value;
    setSearchTerm(localSearchTerm);
    setLoading(true);

    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      const result = await api.get(`/selenium?s=${localSearchTerm}`);
      setItems(result.data);
      setLoading(false);
    }, 300);
  }
  function itemClicked(searchTerm) {
    setItems([]);
    setSearchTerm(searchTerm);
  }
  return (
    <div className="w-full relative" ref={dropdownRef}>
      <p>{label}</p>
      <input
        type="text"
        className="w-full border border-gray-400 py-1 px-2 focus:outline-blue-600"
        onChange={change}
        onFocus={change}
        placeholder={placeholder}
        value={searchTerm}
      />
      {loading ? (
        <div className="w-full absolute z-10 bg-white bottom-0 left-0 translate-y-full h-48 flex items-center justify-center border border-gray-900">
          <Loading />
        </div>
      ) : (
        <ul
          className={`absolute z-10 -bottom-4 left-0 list-none p-0 w-full translate-y-full shadow-lg ${
            items.length ? "border border-gray-900" : ""
          } `}
        >
          {items.map((item) => (
            <li
              key={item}
              className="hover:bg-blue-200 bg-white px-3 py-1 cursor-pointer overflow-hidden"
              onClick={() => itemClicked(item)}
            >
              <SelectItem item={item} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default DynamicSelect;
