import React from "react";
import { CreditCardTypes } from "../../../constants";

function PaymentTitle({ className='', cardNumber }) {
  let cardType = CreditCardTypes.MasterCard;
  const startNumber = Number(cardNumber.substr(0,1))
  switch (startNumber) {
    case 3:
      cardType = CreditCardTypes.AmericanExpress;
      break;
    case 4:
      cardType = CreditCardTypes.VisaCard;
      break;
    case 6:
      cardType = CreditCardTypes.DiscoverCard;
      break;
    default:
      cardType = CreditCardTypes.MasterCard;
  }
  return <p className={className}>{cardType}</p>;
}

export default PaymentTitle;
