import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import CartContext from "../../../contexts/customer/cart/cart-context";
import RecentlyViewedItemsContext from "../../../contexts/customer/recently-viewed-items/recently-viewed-items-context";
import SingleCartItem from "./SingleCartItem";
import SingleViewedItem from "./SingleViewedItem";
function Cart() {
  const { cartItems, totalQuantity, toggleCheckAll, totalSelectedQuantity, totalSelectedPrice } =
    useContext(CartContext);

  const { recentlyViewedItems } = useContext(RecentlyViewedItemsContext);

  const navigate = useNavigate();

  function selectAllBtnClicked() {
    toggleCheckAll();
  }
  return (
    <div>
      <div className="cart__grid">
        {cartItems.length ? (
          <div className="cart">
            <h1 className="cart__title">Shopping Cart</h1>
            <div className="cart__header">
              <div className="cart__select-all" onClick={selectAllBtnClicked}>
                <input
                  type="checkbox"
                  id="checkAll"
                  checked={totalQuantity === totalSelectedQuantity}
                  onChange={toggleCheckAll}
                  className="cart__select-all-input"
                />

                <p className="cart__select-all-text">
                  {totalQuantity !== totalSelectedQuantity ? "Select All" : "Deselect All"}
                </p>
              </div>
              <p className=" pr-8">Price</p>
            </div>
            <hr />
            <div>
              {cartItems.map((item) => (
                <SingleCartItem key={item.productId} item={item}  />
              ))}
            </div>
          </div>
        ) : (
          <div className="cart__empty" id="emptyCart">
            <div className="cart__empty-wrapper">
              <h1 className="cart__empty-title">Your Shopping Cart is Empty</h1>
              <p className="cart__empty-message">
                Your Shopping Cart lives to serve. Give it purpose — fill it with groceries, clothing, household
                supplies, electronics, and more. Continue shopping on homepage, learn about today's deals, or visit your
                Wish List.
              </p>
            </div>
          </div>
        )}
        <div className="cart__subtotal">
          <div className="cart__subtotal-wrapper">
            <p className="cart__subtotal-text">
              Subtotal <span id="totalQuantity">({totalSelectedQuantity})</span> Items:{" "}
              <span className="font-bold" id="totalPrice">
                ${Math.round(totalSelectedPrice * 100) / 100}
              </span>
            </p>
            <button
              className={`cart__subtotal-btn ${totalSelectedQuantity === 0 ? "cart__subtotal-btn-invalid" : ""}`}
              disabled={totalSelectedQuantity === 0 ? "disabled" : ""}
              onClick={() => navigate("/checkout")}
              id="proceedBtn"
            >
              Proceed to Checkout
            </button>
          </div>
          <br />
          <div className="cart__recent">
            <h1 className="cart__recent-title">Your recently viewed items</h1>
            {recentlyViewedItems && recentlyViewedItems.map((item) => <SingleViewedItem key={item.id} item={item}  />)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;
