import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";

import Sidebar from "../../customer/sidebar/Sidebar";
import Modal from "../utilities/Modal";
function SecondNav() {
  const [showModal, setShowModal] = useState(false);


  return (
    <div className="second-nav">
      <button
        className="second-nav__all-btn"
        onClick={() => setShowModal(true)}
        id="hamburgerBtn"
      >
        <GiHamburgerMenu className="text-lg " />
        <span>All</span>
      </button>
      <a href="/selenium/add-remove-elements" target="__blank">
        Test Selenium
      </a>

      <Modal
        show={showModal}
        setShow={setShowModal}
        className="h-screen w-80"
        options={{ closeButton: true , closeClassName: 'text-white'}}
      >
        <Sidebar setShow={setShowModal}/>
      </Modal>
    </div>
  );
}

export default SecondNav;
