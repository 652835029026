import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Title from "./Title";

const itemsFromBackend = [
  { id: "1", content: "Payment should not accept invalid expiration date" },
  { id: "2", content: "In checkout page user should be able to select different adderesses" },
  { id: "3", content: "After Item is removed from cart. total price must get reduced" },
  { id: "4", content: "Full Name should not accept invalid characters" },
  { id: "5", content: "User should be able to edit Address Information" },
];

const columnsFromBackend = {
  1: {
    name: "To do",
    items: itemsFromBackend,
  },
  2: {
    name: "In Progress",
    items: [],
  },
  3: {
    name: "Done",
    items: [],
  },
};

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

function DragDrop() {
  const [columns, setColumns] = useState(columnsFromBackend);
  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Drag and Drop" id="title" />
      <div className="flex justify-center h-full" >
        <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div className="flex flex-col items-center" key={columnId}>
                <h2>{column.name}</h2>
                <div style={{ margin: 8 }}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className={` w-60 p-2 h-96 ${snapshot.isDraggingOver ? "bg-gray-200" : "bg-gray-100"}`}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: "none",
                                        ...provided.draggableProps.style,
                                      }}
                                      className="py-2 px-3 bg-white mb-1 rounded shadow-md  cursor-pointer hover:bg-gray-200 text-sm"
                                    >
                                      {item.content}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    </div>
  );
}

export default DragDrop;
