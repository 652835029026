import React, { useState } from "react";
import { getUniqueId } from "../../helper";
import Title from "./Title";
import { HiTrash } from "react-icons/hi";

function DynamicControls() {
  const [inputs, setInputs] = useState([
    {
      id: getUniqueId(),
      title: "",
      value: "",
    },
  ]);

  function addItem() {
    setInputs((inputs) => {
      const item = { id: getUniqueId(), title: "", value: "" };

      const newItems = [...inputs, item];

      return newItems;
    });
  }
  function removeItem(index) {
    setInputs((inputs) => {
      const newItems = [...inputs];
      newItems.splice(index, 1);

      return newItems;
    });
  }
  function handleChange(e, key) {
    const index = e.target.dataset.index;
    setInputs((inputs) => {
      const newItems = [...inputs];
      newItems[index][key] = e.target.value;

      return newItems;
    });
  }

  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Dynamic Controls" id="title" />
      <div>
        <div className="flex gap-4">
          <div className="w-6/12 font-bold">Title</div>
          <div className="w-6/12 font-bold">Value</div>
        </div>
        {inputs.map((item, index) => (
          <div className="flex gap-4 py-4 relative object-input-container" key={item.id}>
            <input
              type={"text"}
              className="border border-gray-400 bg-gray-200 py-1 px-2 focus:outline-none w-6/12"
              onChange={(e) => handleChange(e, "title")}
              value={item.title}
              data-index={index}
              placeholder="Enter Title"
            />
            <input
              type={"text"}
              className="border bg-white py-1 px-2 focus:outline-none w-5/12"
              onChange={(e) => handleChange(e, "value")}
              defaultValue={item.value}
              data-index={index}
              placeholder="Enter Value"
            />
            <button onClick={() => removeItem(index)} type="button" data-id={item.id}>
              <HiTrash className="hover:text-red-600 text-xl" />
            </button>
          </div>
        ))}
        <div className="flex justify-end">
          <button
            onClick={() => addItem()}
            type="button"
            id="addBtn"
            className="py-2 px-3 bg-blue-800 rounded text-blue-100 hover:bg-blue-700 mr-2"
          >
            Add Control
          </button>
        </div>
      </div>
    </div>
  );
}

export default DynamicControls;
