import React, { useContext, useEffect, useState } from "react";
import { BsPersonCircle } from "react-icons/bs";
import { HiArrowLeft, HiChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import AuthContext from "../../../contexts/shared/auth/auth-context";
import ProductContext from "../../../contexts/customer/products/product-context";
import SidebarContext from "../../../contexts/customer/sidebar/sidebar-context";
import api from "../../../contexts/api";

function Sidebar({ setShow }) {
  const navigate = useNavigate();

  const { setProducts } = useContext(ProductContext);
  const [items, setItems] = useState([]);
  const [showHeader, setShowHeader] = useState(true);
  const { user } = useContext(AuthContext);
  const { categories } = useContext(SidebarContext);

  useEffect(() => {
    setFirstLevel();
  }, [categories]);

  function setFirstLevel() {
    const firstLevelItems = Object.keys(categories).map((key, index) => ({
      id: categories[key].id,
      title: categories[key].title,
      level: categories[key].level,
    }));
    setShowHeader(true);
    setItems(firstLevelItems);
  }
  function setSubLevel(id) {
    setShowHeader(false);
    setItems([...categories[id].children]);
  }
  async function itemClicked(item) {
    if (item.level && item.level === 1) {
      setSubLevel(item.id);
    } else {
      const result = await api.get(`/product?categoryId=${item.id}`);
      setProducts(result.data);
      navigate("/products");
      setShow(false);
    }
  }

  return (
    <div id="sidebar">
      <div className="sidebar__header">
        <BsPersonCircle className="text-3xl" />
        <p className="sidebar__header-greeting" id="sidebarGreeting"> Hello, {user ? user.name : "Sign In"}</p>
      </div>

      <div className="sidebar__content">
        {showHeader && <h1 className="sidebar__content-header" id="contentHeader">Shop By department</h1>}
        {!showHeader && (
          <button
            className="sidebar_content-submenu"
            onClick={setFirstLevel}
            id="submenuBtn"
          >
            <HiArrowLeft /> Main Menu
          </button>
        )}
        {items &&
          items.map((item) => (
            <div
              key={item.id}
              className="sidebar_content-item"
              onClick={() => itemClicked(item)}
              data-id={item.id}
            >
              <span>{item.title}</span>
              {item.level && <HiChevronRight className="text-gray-500 text-xl" />}
            </div>
          ))}
      </div>
    </div>
  );
}

export default Sidebar;
