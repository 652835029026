import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";


import Categories from "../category/Categories";
import HomeContext from "../../../contexts/customer/home/home-context";
import Image from "../../shared/utilities/Image";


function Home() {
  
  const {homeData} = useContext(HomeContext); 
  const navigate = useNavigate();

  return (
    <div id="home">
      <div className="home__hero">
        <img
          src="/images/hero-image.avif"
          className="home__hero-image"
          id="heroImage"
          alt="imgae 1"
        />
      </div>

      <div className="home__content">
        <h1 className="home__categories-title" id="categoryTitle">Shop By Category</h1>
        {homeData && <Categories items={homeData.categories} />}
        <br />

        {homeData && (
          <>
            <div className="home__products" id="topRatedProducts">
              <h1 className="home__products-title">{homeData.topRated.title}</h1>
              <div className="home__products-grid">
                {homeData.topRated.products.map((item) => (
                  <div key={item.id} data-id={item.id}>
                    <Image src={item.imageUrl} alt={item.name} onClick={() => navigate(`/products/${item.id}`)} />
                  </div>
                ))}
              </div>
            </div>
            <div className=" home__products" id="latestProducts">
              <h1 className="home__products-title">{homeData.latest.title}</h1>
              <div className="home__products-grid">
                {homeData.latest.products.map((item) => (
                  <div key={item.id} data-id={item.id}>
                    <Image src={item.imageUrl} alt={item.name}  onClick={() => navigate(`/products/${item.id}`)} />
                  </div>
                ))}
              </div>
            </div>
            
          </>
        )}
      </div>
    </div>
  );
}

export default Home;
