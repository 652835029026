import React, { useContext, useEffect, useReducer } from "react";
import {toast} from 'react-toastify'

import { SET_PRODUCTS, RESET, ADD_PRODUCT, DELETE_PRODUCT, EDIT_PRODUCT } from "./company-product-actions";
import AuthContext from "../../../contexts/shared/auth/auth-context";
import CompanyProductReducer from "./company-product-reducer";
import CompanyProductContext from "./company-product-context";
import api from "../../api";
function CompanyProductState({ children }) {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setState();
  }, [user]);

  const initialState = {
    items: [],
  };

  const [state, dispatch] = useReducer(CompanyProductReducer, initialState);

  // Called to load data from database
  const setState = async () => {
    if (!user) {
      dispatch({
        type: RESET,
      });
      return;
    }

    try {
      if (user && user.role === "company") {
        const result = await api.get(
          `/product?sellerId=${user.roleId}`
        );
        dispatch({
          type: SET_PRODUCTS,
          payload: result.data,
        });
      }
    } catch (error) {}
  };

  const addProduct = async (formData) => {
    try {
      const result = await api.post(
        "/product",
        formData
      );
      dispatch({ type: ADD_PRODUCT, payload: result.data });
      
      toast.success("Product Inserted Successfully",{
        position: 'bottom-right',
        draggable: true,
      });

    } catch (error) {
      return Promise.reject(error);
    }
  };
  const updateProduct = async (formData, id) => {
    try {
      const result = await api.put(
        `/product/${id}`,
        formData
      );
      dispatch({ type: EDIT_PRODUCT, payload: result.data });
      
      toast.success("Product Updated Successfully",{
        position: 'bottom-right',
        draggable: true,
      });

    } catch (error) {
      return Promise.reject(error);
    }
  };

  const deleteProduct = async (id) => {
    try {
      await api.delete(`/product/${id}`);
      dispatch({type: DELETE_PRODUCT, payload: id});
      
      toast.success("Product Deleted Successfully",{
        position: 'bottom-right',
        draggable: true,
      });

    } catch (error) {
      console.log(error)
    }
  };

  return (
    <CompanyProductContext.Provider
      value={{ products: state.items, addProduct , deleteProduct, updateProduct }}
    >
      {children}
    </CompanyProductContext.Provider>
  );
}

export default CompanyProductState;
