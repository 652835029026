import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import AuthContext from "../contexts/shared/auth/auth-context";

function AuthenticatedRoute({Component,roles=[]}) {
  const { isAuthorized} = useContext(AuthContext);

  return <>{isAuthorized(roles) ? <Component /> : <Navigate to="/"/>}</>;
}

export default AuthenticatedRoute;
