import React, { useState } from "react";
import Title from "./Title";

const TOTAL_ITEMS = 60;
const ITEMS_PER_PAGE = 12;
const TOTAL_PAGES = TOTAL_ITEMS / ITEMS_PER_PAGE;

const fullItems = new Array(96).fill(0).map((item, index) => index + 1);
const pages = new Array(TOTAL_PAGES).fill(0).map((item, index) => index);

function Pagination() {
  const [items, setItems] = useState(fullItems.slice(0, ITEMS_PER_PAGE));
  const [activePage, setActivePage] = useState(0);

  function navItemClicked(index) {
    setActivePage(index);
    setItems(fullItems.slice(index * ITEMS_PER_PAGE, (index + 1) * ITEMS_PER_PAGE));
  }
  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Pagination" id="title" />
      <p>Pagination is used to break up a block of content into navigable pages on a webpage.</p>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        {items.map((item) => (
          <p className="p-16 text-center text-lg font-semibold shadow rounded bg-blue-100 text-gray-800" key={item}>
            {item}
          </p>
        ))}
      </div>
      {/* Pagination Nav */}

      <nav className="flex justify-center">
        <div className="inline-flex -space-x-px">
          <div>
            <button
              className={`selenium__pagination-button ${activePage === 0 ? "hidden" : ""} `}
              onClick={() => navItemClicked(activePage - 1)}
              id="previousBtn"
            >
              Previous
            </button>
          </div>
          {pages.map((item, index) => (
            <div key={item}>
              <button
                className={`selenium__pagination-button  ${index === activePage ? "selenium__pagination-active" : ""}`}
                onClick={() => navItemClicked(item)}
                data-id={item+1}
              >
                {item + 1}
              </button>
            </div>
          ))}

          <div>
            <button
              className={`selenium__pagination-button ${activePage + 1 === TOTAL_PAGES ? "hidden" : ""}`}
              onClick={() => navItemClicked(activePage + 1)}
              id="nextBtn"
            >
              Next
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Pagination;
