import React, { useEffect, useState } from "react";

import AuthContext from "./auth-context";
import api from "../../api";
function AuthState({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser) {
      setUser(currentUser);
    }
  }, []);

  const login = async ({ email, password }) => {
    try {
      const result = await api.post("/auth/login", {
        email,
        password,
      });

      return setLocalUser(result.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const signup = async ({ name, email, password }) => {
    try {
      const result = await api.post("/auth/sign-up", {
        name,
        email,
        password,
      });
      return setLocalUser(result.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const updateInformation = async({name,email,phoneNumber})=>{
    try {
      const result = await api.put("/auth",{
        name,email,phoneNumber
      });
      return setLocalUser(result.data)
    } catch (error) {
      console.log(error)
      throw error;
    }
  }
  const changePassword = async ({previousPassword, newPassword})=>{
    try {
      await api.post("/auth/change-password",{
        previousPassword,
        newPassword
      });
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  const createSellerAccount = async ({ name, email, password, description, url }) => {
    try {
      const result = await api.post("/auth/sign-up/seller", {
        name,
        email,
        password,
        description,
        url,
      });
      return setLocalUser(result.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  function getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  function setLocalUser(user) {
    if (user.accessToken) {
      localStorage.setItem("user", JSON.stringify(user));
      setUser(user);
      return { role: user.role };
    }
  }

  function isAuthorized(roles) {
    // if a protected route is refreshed from browser window the component well navigate to home
    // because setting state is async it runs after sync code and user is set afterwards
    // Thats why we need user from local storage
    const localUser = user || getCurrentUser();
    if (!localUser) {
      return false;
    }
    // check if user role is among roles specified for the route
    // 'company' and ['company'] both are acceptable
    const modifiedRoles = typeof roles === "string" ? [roles] : roles;
    if (modifiedRoles.length && !modifiedRoles.includes(localUser.role)) {
      return false;
    }
    return true;
  }
  return (
    <AuthContext.Provider value={{ user, setUser, login, signup, createSellerAccount, isAuthorized , updateInformation ,changePassword}}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthState;
