export const phoneNumberValidation = {
    regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
    message: "please enter valid phone number"
}
export const nameValidation = {
    regex: /^(?![\s.]+$)[a-zA-Z\s.]*$/i,
    message: "only alphabets are allowed",
}
//Ref : https://stackoverflow.com/questions/578406/what-is-the-ultimate-postal-code-and-zip-regex
// neeraj t answer
export const zipCodeValidation = {
    regex: /^\d{5}([\-]?\d{4})?$/,
    message: "zipcode can be 5 or 9 digits"
}
// Ref: https://stackoverflow.com/questions/11757013/regular-expressions-for-city-name
// user2603432 answer
export const cityValidation = {
    regex: /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
    message: "please enter valid city name"
};

export const addressValidation = {
    regex: /^[#.0-9a-zA-Z\s,-]+$/,
    message: "please enter valid address"
}