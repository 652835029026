import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../contexts/shared/auth/auth-context'

function TopNav() {
    const {user , setUser} = useContext(AuthContext);
    const navigate = useNavigate();

    function logout() {
        localStorage.removeItem("user");
        setUser(null);
        navigate("/");
    }

  return (
    <div className="w-full bg-gray-900 text-gray-100 flex py-3 px-4 gap-6 items-center">
    <h1
      className="text-xl font-bold cursor-pointer"
      onClick={() => navigate("/")}
    >
      TEKSCHOOL
    </h1>

    <div className="grow"></div>

    {/* Sign in */}
    {!user && (
      <div>
        <button
          className="border border-gray-900 hover:border-gray-500 p-2"
          onClick={() => navigate("/auth/login")}
        >
          Sign in
        </button>
      </div>
    )}
    {user && (
      <div>
        <button
          className="border border-gray-900 hover:border-gray-500 p-2"
          onClick={logout}
        >
          Log out
        </button>
      </div>
    )}
  </div>
  )
}

export default TopNav