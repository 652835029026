import React from "react";
import Title from "./Title";

function Checkbox() {
  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="CheckBoxes" />

      <form>
        <div id="firstQuestion">
          <p>What progamming languages do you like?</p>
          <div>
            <input type="checkbox" id="javaInput" name="java" value="java" />
            <label for="javaInput"> Java</label>
          </div>
          <div>
            <input type="checkbox" id="javascriptInput" name="javascript" value="javascript" />
            <label for="javascriptInput"> Javascript</label>
          </div>
          <div>
            <input type="checkbox" id="cSharpInput" name="cSharp" value="cSharp" />
            <label for="cSharpInput"> C#</label>
          </div>
          <div>
            <input type="checkbox" id="goInput" name="go" value="go" />
            <label for="goInput"> Go</label>
          </div>
          <div>
            <input type="checkbox" id="cPlusPlusInput" name="cPlusPlus" value="cPlusPlus" disabled />
            <label title="come on! no body likes c++" for="cPlusPlusInput">
              {" "}C++
            </label>
          </div>
        </div>
        <br />
        <fieldset id="secondQuestion" className="border border-solid border-gray-300 p-3">
          <legend>Choose your interests</legend>
          <div className="flex gap-3">
            <input type="checkbox" id="codingInput" name="coding" value="coding" />
            <label for="codingInput">Coding</label>
          </div>
          <div className="flex gap-3">
            <input type="checkbox" id="musicInput" name="music" value="music" />
            <label for="musicInput">Music</label>
          </div>
          <div className="flex gap-3">
            <input type="checkbox" id="movieInput" name="movie" value="movie" />
            <label for="movieInput">movies</label>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default Checkbox;
