import React, { useContext } from "react";
import AddressContext from "../../../../contexts/customer/address/address-context";

function Address({ address }) {
  const { selectedAddressId, setSelectedAddressId } = useContext(AddressContext);

  return (
    <div
      className={`${selectedAddressId === address.id && "checkout__address-item-selected"} checkout__address-item`}
      onClick={() => setSelectedAddressId(address.id)}
      data-id={address.id}
    >
      <input
        type="radio"
        checked={selectedAddressId === address.id}
        onChange={() => setSelectedAddressId(address.id)}
      />
      <p className="checkout__address-item-name">{address.fullName}</p>
      <p>{address.street} ,</p>
      <p>{address.apartment} ,</p>
      <p>{address.city.toUpperCase()} ,</p>
      <p>{address.state.toUpperCase()} ,</p>
      <p>{address.zipCode},</p>
      <p>{address.country}</p>
    </div>
  );
}

export default Address;
