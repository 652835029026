import React from 'react'
import Title from './Title'

function Iframe() {
  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Iframe" id="title" />
    <p>An HTML iframe is used to display a web page within a web page.</p>
    <iframe src="/templates/test.html" className="w-full h-96 border"  title="Iframe Example"></iframe>

    </div>
  )
}

export default Iframe