import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import ProductContext from "../../../contexts/customer/products/product-context";
import Image from "../utilities/Image";
import Star from "../utilities/Star";
import api from "../../../contexts/api";
function Products() {
  const { products, setProducts } = useContext(ProductContext);

  let navigate = useNavigate();

  async function companyClicked(sellerId) {
    const newProducts = await api.get(
      `/product?sellerId=${sellerId}`
    );
    setProducts(newProducts.data);
    navigate("/products");
  }

  return (
    <div className="products">
      {products &&
        products.map((item) => (
          <div className="products__layout" key={item.id} data-id={item.id}>
              <Image
                src={item.imageUrl}
                onClick={() => navigate(`/products/${item.id}`)}
                alt={item.name}
              />
            <p
              className="products__name"
              onClick={() => navigate(`/products/${item.id}`)}
            >
              {item.name}
            </p>
            <p
              className="products__company"
              onClick={() => companyClicked(item.sellerId)}
            >
              {item.companyName}
            </p>
            <Star rating={item.rating} />
          </div>
        ))}
    </div>
  );
}

export default Products;
