import React, { useLayoutEffect,useEffect, useRef, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

const TextArea = ({  onChange, value , edit , ...rest}) => {
  const textAreaRef = useRef(null);
  const [quill,setQuill] = useState(null);
  
  useLayoutEffect(() => {
  
    let tempQuill = new Quill(textAreaRef.current, {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          ["clean"],
        ],
      },
      theme: "snow",
    });
    const handleContentsChange =  (delta, oldDelta, source) =>{
      // because tailwind css removes all default styles we wrap everything in a div and give styles for
      // quill tags in index.css
      
      onChange(`<div class="myQuill"> ${tempQuill.root.innerHTML}</div>`);
    }
    tempQuill.on("text-change", handleContentsChange );

    setQuill(tempQuill);
    return () => {
      tempQuill.off('text-change', handleContentsChange);
      setQuill(null);
    };
  }, []);
  
  useEffect(()=>{
      
    if(!edit || !quill){      
      return;
    }
    quill.clipboard.dangerouslyPasteHTML(0, value);
  },[edit , quill]);

  return (
    <div>
      <div ref={textAreaRef} className="bg-white" style={{ minHeight: "130px" }} {...rest}>
      </div>
    </div>
  );
};

export default TextArea;
