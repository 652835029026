import React from 'react'
import Addresses from './Addresses'

function Index() {
  return (
    <div>
        <h1 className="account__title">Your Addresses</h1>
        <Addresses />
    </div>
  )
}

export default Index