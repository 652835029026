import React from "react";
import { NavLink } from "react-router-dom";
import {HiOutlineArrowLeft} from 'react-icons/hi'
function Nav() {
  return (
    
      <div className="lg:px-16 py-8 h-screen fixed left-0 top-0 overflow-y-auto">
         <a href="/" className="py-1 px-2 cursor-pointer hover:underline w-full flex items-center gap-1">
          <HiOutlineArrowLeft /> Home
        </a>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/add-remove-elements"}
        >
          Add/Remove Elements
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/broken-images"}
        >
          Broken Images
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/challenging-dom"}
        >
          Challenging DOM
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/checkbox"}
        >
          Checkboxes
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/context-menu"}
        >
          Context Menu
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/drag-drop"}
        >
          Drag and Drop
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/dropdown"}
        >
          Drop Down
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/dynamic-content"}
        >
          Dynamic Content
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/dynamic-controls"}
        >
          Dynamic Controls
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/dynamic-select"}
        >
          Dynamic Select
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/floating-menu"}
        >
          Floating Menu
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/hover"}
        >
          Hover
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/iframe"}
        >
          Iframe
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/infinite-scroll"}
        >
          Infinite Scroll
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/inputs"}
        >
          Inputs
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/javascript-alerts"}
        >
          Javascript Alerts
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/key-press"}
        >
          Key Press
        </NavLink>
      
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/links"}
        >
          Links
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/pagination"}
        >
          Pagination
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/radio"}
        >
          Radio Button
        </NavLink>
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/alert"}
        >
          Show Alert
        </NavLink>
      
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/upload"}
        >
          Upload Image
        </NavLink>
        
        
        <NavLink
          className={({ isActive }) => `py-1 px-2 cursor-pointer  ${isActive ? "font-bold" : "hover:underline"} w-full block`}
          to={"/selenium/wysiwig"}
        >
          WYSIWIG Editor
        </NavLink>
      </div>
      
  );
}

export default Nav;
