import React from "react";
import Title from "./Title";

function Links() {
  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Html Links" id="title" />

      <a href="https://www.google.com/" className="hover:underline text-blue-800">
        same tab
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        className="hover:underline text-blue-800"
        href="https://www.google.com/"
      >
        new tab with html
      </a>

      <a
        href=""
        className="hover:underline text-blue-800"
        onClick={() => window.open("http://www.facebook.com", "", "width=700,height=700")}
      >
        new window with js
      </a>
    </div>
  );
}

export default Links;
