import React, { useContext, useRef } from "react";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import Label from "../../common/Label";
import AuthContext from "../../../contexts/shared/auth/auth-context";

const schema = Yup.object().shape({
  previousPassword: Yup.string().required("Previous Password is a required field"),
  newPassword: Yup.string()
  .required("New Password is a required field")
  .min(8, "at least 8 characters\n")
  .max(30, "at most 30 characters\n")
  .matches(/[a-z]/, "at least one lowercase char\n")
  .matches(/[A-Z]/, "at least one uppercase char\n")
  .matches(/[0-9]+/, "at least one number\n")
  .matches(/[a-zA-Z0-9]+[^a-zA-Z0-9\s]+/, "at least one special char\n"),
  confirmPassword: Yup.string()
    .required("Confirm Password is a required field")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});

const initialValues = {
  previousPassword: "",
  newPassword: "",
  confirmPassword: "",
};

function ChangePassword() {
  const { changePassword } = useContext(AuthContext);
  const formikRef = useRef();
  async function formSubmitted(values) {
    try {
      await changePassword(values);
      formikRef.current.resetForm();
      toast.success("Password Updated Successfully", {
        position: "bottom-right",
        draggable: true,
      });
    } catch (error) {
      toast.error(error.response?.data?.message, {
        position: "bottom-right",
        draggable: true,
      });
    }
  }
  return (
    <div>
      <Formik
        innerRef={formikRef}
        validateOnBlur={false}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={formSubmitted}
      >
        {({ values, setFieldValue, isSubmitting, isValid, dirty, status, resetForm }) => (
          <Form className="account__form" id="credentialsForm">
            {/* Name*/}
            <div className="account__personal-input-wrapper">
              <Label title="Previous Password" className="account__personal-label" />
              <Field
                type="password"
                name="previousPassword"
                onBlur={() => {}}
                className="account__input"
                id="previousPasswordInput"
              />
              <ErrorMessage
                name="previousPassword"
                id="previousPasswordError"
                component="div"
                className="error"
              />
            </div>

            {/* Password */}
            <div className="account__personal-input-wrapper">
              <Label title="New Password" className="account__personal-label" />
              <Field
                type="password"
                name="newPassword"
                onBlur={() => {}}
                className="account__input"
                id="newPasswordInput"
              />
              <ErrorMessage
                name="newPassword"
                id="newPasswordError"
                component="div"
                className="error"
              />
            </div>
            {/*Confirm Password */}
            <div className="account__personal-input-wrapper">
              <Label title="Confirm Password" className="account__personal-label" />
              <Field
                type="password"
                name="confirmPassword"
                onBlur={() => {}}
                className="account__input"
                id="confirmPasswordInput"
              />
              <ErrorMessage
                name="confirmPassword"
                id="confirmPasswordError"
                component="div"
                className="error"
              />
            </div>
            {/* Submit Button */}
            <div className="account__btn-wrapper ">
              <button
                type="submit"
                id="credentialsSubmitBtn"
                className={`account__btn-submit`}
                disabled={!dirty}
              >
                Change Password
              </button>
              <button
                id="credentialsCancelBtn"
                onClick={resetForm}
                type="button"
                className={`account__btn-cancel ${!dirty && "hidden"}`}
              >
                Cancel
              </button>
            </div>
            {status && <div className="error">{status}</div>}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ChangePassword;
