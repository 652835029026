export const Roles = {
  Company: "company",
  Customer: "customer",
};

export const CreditCardTypes = {
  MasterCard: "Master Card",
  VisaCard: "Visa Card",
  AmericanExpress: "American Express",
  DiscoverCard: "Discover Card",
};

export const OrderStatuses = {
  Shipping: 0,
  Delivered: 1,
  Canceled: 2,
};
