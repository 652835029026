import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import CartContext from "../../../contexts/customer/cart/cart-context";
import AddressContext from "../../../contexts/customer/address/address-context";
import PaymentContext from "../../../contexts/customer/payment/payment-context";
import OrderContext from "../../../contexts/customer/order/order-context";

function PlaceOrder({ id, isBuyAgain = false }) {
  const { totalSelectedQuantity, totalSelectedPrice, placeOrder } = useContext(CartContext);
  const { buyAgain, getOrderById } = useContext(OrderContext);
  const { selectedAddressId } = useContext(AddressContext);
  const { checkoutSelectedMethodId } = useContext(PaymentContext);

  const order = isBuyAgain ? getOrderById(id) : null;

  const navigate = useNavigate();
  async function placeOrderClicked() {
    try {
      if (id) {
        const result = await buyAgain(id);
        navigate(`/order/${result.id}/confirm`);
        toast.success("Order Placed Successfully", {
          position: "bottom-right",
          draggable: true,
        });
        return;
      }
      const result = await placeOrder();
      navigate(`/order/${result.id}/confirm`);
      toast.success("Order Placed Successfully", {
        position: "bottom-right",
        draggable: true,
      });
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="checkout__order">
      <button
        className={`checkout__order-btn`}
        disabled={selectedAddressId === -1 || checkoutSelectedMethodId === -1}
        onClick={placeOrderClicked}
        id="placeOrderBtn"
      >
        Place Your Order
      </button>
      <p id="selectionNote" className={`${selectedAddressId === -1 || checkoutSelectedMethodId === -1 ? "" : "hidden"} text-xs`}>
        <span className="font-bold">Note: </span>Please make sure that you have seleted at least one address and one
        payment method
      </p>
      <h1 className="checkout__order-title">Order Summary</h1>
      <div className="checkout__order-quantity">
        <span>Item Count:</span> <span>{isBuyAgain ? order.quantity: totalSelectedQuantity} </span>
      </div>
      <div className="checkout__order-total">
        <span>Payment Total: </span>
        <span id="paymentTotal">$ {Math.round( (isBuyAgain ? order.price : totalSelectedPrice) * 100) / 100} </span>
      </div>
    </div>
  );
}

export default PlaceOrder;
