import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  EDIT_PRODUCT,
  SET_PRODUCTS,
  RESET,
} from "./company-product-actions";
const reducer = (state, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return {
        items: [action.payload, ...state.items],
      };
    case DELETE_PRODUCT:
      return {
        items: state.items.filter((item) => item.id !== action.payload),
      };
    case EDIT_PRODUCT:
      return {
        items: state.items.map((item) =>
          item.id === action.payload.id
            ? { ...action.payload }
            : item
        ),
      };
    case SET_PRODUCTS:
      return {
        items: [...action.payload],
      };
    case RESET:
      return {
        items: [],
      };
  }
};

export default reducer;
