import React, { useContext } from "react";

import AddressContext from "../../../../contexts/customer/address/address-context";
import Address from "./Address";

function Addresses() {
  const { addresses } = useContext(AddressContext);
  return (
    <div className="checkout__addresses">
      {addresses.map((item) => (
        <Address key={item.id} address={item}  />
      ))}
    </div>
  );
}

export default Addresses;
