import React from 'react'
import { useNavigate } from 'react-router-dom'

function SecondNav() {
    const navigate = useNavigate();
  return (
    <div className="bg-gray-700 text-gray-100 py-1 px-4 flex gap-2 text-sm">
      <button
        className="flex gap-1 items-center border border-gray-700 hover:border-gray-100 py-1 px-2"
        onClick={()=>navigate("/company/dashboard")}
      >
        All products
      </button>
      <button
        className="flex gap-1 items-center border border-gray-700 hover:border-gray-100 py-1 px-2"
        onClick={()=>navigate("/company/product/new")}
      >
        New Product
      </button>
    </div>
  )
}

export default SecondNav