import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OrderStatuses } from "../../../constants";
import Header from "./Header";
import OrderStatus from "./OrderStatus";
import SingleOrderItem from "./SingleOrderItem";

function Order({ index = 0, order }) {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (index === 0) setShow(true);
  }, [index]);

  function cancelClicked() {
    navigate(`/order/${order.id}?type=cancel`);
  }
  async function returnClicked(){
    navigate(`/order/${order.id}?type=return`);
  }
  return (
    <div className="order" data-id={order.id}>
      {/* Header */}
      <Header show={show} setShow={setShow} order={order} />
      {show && (
        <div className="order__content">
          <div className="order__content-items">
            <OrderStatus order={order} />
            {order.products.map((item, index) => (
              <SingleOrderItem product={item} key={item.id} />
            ))}
          </div>
          <div className="order__content-actions">
            {order.status!== OrderStatuses.Canceled && <><button className="order__content-btn" id="cancelBtn" onClick={cancelClicked}>
              Cancel The Order
            </button>
            <button className="order__content-btn" id="returnBtn" onClick={returnClicked}>Return Items</button></>}
            <button className="order__content-btn" id="buyAgainBtn" onClick={()=>navigate(`/checkout/order/${order.id}`)}>Buy again</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Order;
