import React, { useContext, useState } from "react";
import AddressContext from "../../../contexts/customer/address/address-context";
import Modal from "../../shared/utilities/Modal";
import NewAddress from "./NewAddress";

function Address({ address }) {
  
  const { removeAddress } = useContext(AddressContext);
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="account__address-single" data-id={address.id}>
      <div>
        <p className="font-bold">{address.fullName}</p>
        <p>{address.street}</p>
        <p>{address.apartment}</p>
        <p>
          {address.city.toUpperCase()} , {address.state.toUpperCase()} {address.zipCode}
        </p>
        <p>{address.country}</p>
        <p>phone Number: {address.phoneNumber}</p>
      </div>
      <div className="account__address-btn-wrapper">
        <button className="account__address-btn" onClick={() => setShowModal(true)}>
          Edit
        </button>
        <p> | </p>
        <button className="account__address-btn" onClick={() => removeAddress(address)}>
          Remove
        </button>
      </div>
      <Modal
        show={showModal}
        setShow={setShowModal}
        options={{ closeButton: true }}
        className="account__address-modal"
      >
        <NewAddress setShow={setShowModal} address={address} edit={true} />
      </Modal>
    </div>
  );
}

export default Address;
