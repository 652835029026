import React from 'react'
import NewReview from './NewReview'
import Reviews from './Reviews'

function Index({setShow, productId}) {
  return (
    <div className='review'>
        <NewReview setShow={setShow} productId={productId} />
        <Reviews productId={productId} />
    </div>
  )
}

export default Index