import React from "react";
import Title from "../Title";
import ReactDOM from "react-dom";
import DummyText from "./DummyText";
import Navbar from "./Navbar";
function FloatingMenu() {
  const ROOT = document.getElementById("root");
  return (
    <div className="flex flex-col gap-4">
      <Navbar />
      <Title title="Floating Menu" id="title" />
      <DummyText />
    </div>
  );
}

export default FloatingMenu;
