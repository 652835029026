import React from 'react'
import Title from './Title'

function BrokenImages() {
  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Broken Images" id="title" />
      <div className='flex gap-4'>
        <div className='w-24'>
            <img src='/images/profile.png' alt="profile" />
        </div>
        <div className='w-24'>
            <img src='/images/jaws.png' alt="jaws" />
        </div>
        <div className='w-24'>
            <img src='/images/cheese.png' alt="who stole my cheese" />
        </div>
      </div>
      
    </div>
  )
}

export default BrokenImages