import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import ProductContext from "../../../contexts/customer/products/product-context";
import Image from "../../shared/utilities/Image";
import api from "../../../contexts/api";

function Category({ category }) {
  const navigate = useNavigate();
  const { setProducts } = useContext(ProductContext);

  async function categoryClicked(id) {
    try {
      
    const result = await api.get(
      `/product?categoryId=${id}`
    );
    
    setProducts(result.data);
    navigate("/products");
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="home__category">
      <h1 className="home__category-title">{category.categoryName}</h1>
      <div>
        {category.subCategories.length === 1 ? (
          <Image
            src={category.subCategories[0].imageUrl}
            alt={category.subCategories[0].subCategoryName}
            onClick={() => categoryClicked(category.subCategories[0].subCategoryId)}
          />
        ) : (
          <div className="home__category-grid">
            {category.subCategories.map((item) => (
              <div
                className="home__category-item"
                key={item.subCategoryId}
                onClick={() => categoryClicked(item.subCategoryId)}
              >
                <Image src={item.imageUrl} alt={item.subCategoryName} />
                <p className="home__category-item-text">{item.subCategoryName}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Category;
