import React from 'react'
import { Outlet } from 'react-router-dom'
import Nav from './Nav'

function index() {
  return (
    <div className='md:flex gap-2 bg-gray-100 p-4 min-h-screen'>
        <div className='md:w-3/12 p-4'>
            <Nav />
        </div>
        <div className='md:w-8/12 p-4 bg-white shadow rounded'>
            <Outlet />
        </div>
    </div>
  )
}

export default index