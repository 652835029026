import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../shared/utilities/Modal";
import ReviewIndex from "./review/Index";

const baseURL = process.env.REACT_APP_API_URL;

function SingleOrderItem({ product }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="order__item" data-id={product.id}>
      <img src={`${baseURL}${product.imageUrl}`} className="order__item-img" alt={product.name} />
      <div className="text-sm">
        <p className="order__item-name" onClick={() => navigate(`/products/${product.id}`)}>
          {product.descriptiveName}
        </p>
        <p className="order__item-quantity">
          ({product.quantity} {product.quantity > 1 ? "items" : "item"})
        </p>
        <div className="order__item-btn-wrapper">
          <button
            className="order__item-btn"
            onClick={() => setShowModal(true)}
            id="reviewBtn"
          >
            Review
          </button>
        </div>
      </div>

      <Modal show={showModal} setShow={setShowModal} options={{ closeButton: true }} className="account__address-modal">
        <ReviewIndex setShow={setShowModal} productId={product.id} />
      </Modal>
    </div>
  );
}

export default SingleOrderItem;
