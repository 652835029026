import React from "react";
import Title from "./Title";
import Label from "../common/Label";
function Dropdown() {
  const items = [
    { id: 1001, value: "javascript", text: "JavaScript" },
    { id: 1002, value: "php", text: "PHP" },
    { id: 1003, value: "java", text: "Java" },
    { id: 1004, value: "golang", text: "Golang" },
    { id: 1005, value: "python", text: "Python" },
    { id: 1006, value: "cSharp", text: "C#" },
    { id: 1007, value: "cPlusPlus", text: "C++" },
    { id: 1008, value: "erlang", text: "Erlang" },
  ];

  const countries = [
    { id: 2001, value: "Afghanistan", text: "Afghanistan" },
    { id: 2002, value: "India", text: "India" },
    { id: 2003, value: "United States", text: "United States" },
    { id: 2004, value: "Cuba", text: "Cuba" },
    { id: 2005, value: "UAE", text: "United Arab Emirates" },
  ];

  const languges = [
    { id: 3001, value: "English", text: "English" },
    { id: 3002, value: "Persian", text: "Persian" },
    { id: 3003, value: "Pashto", text: "Pashto" },
    { id: 3004, value: "German", text: "German" },
    { id: 3005, value: "Italian", text: "Italian" },
  ];

  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Dropdown" />
      <div className="flex flex-col gap-3 md:w-80">
        <Label title="Select a programming language" />
        <select name="language" id="programmingLanguageSelect" className="bg-gray-100 p-2">
          {items.map((item, index) => (
            <option key={item.id} value={item.value} data-id={item.id} data-index={index}>
              {item.text}
            </option>
          ))}
        </select>
      </div>
      <br />
      <div className="flex flex-col gap-3 md:w-80">
        <Label title="Select your country" />
        <select name="country" id="countrySelect" className="bg-gray-100 p-2">
          {countries.map((item, index) => (
            <option key={item.id} value={item.value} data-id={item.id} data-index={index}>
              {item.text}
            </option>
          ))}
        </select>
      </div>
      <br />

      <div className="flex flex-col gap-3 md:w-80">
        <Label title="Select website's language" />
        <select name="country" id="languageSelect" className="bg-gray-100 p-2">
          {languges.map((item, index) => (
            <option key={item.id} value={item.value} data-id={item.id} data-index={index}>
              {item.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default Dropdown;
