import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import CartContext from "../../../contexts/customer/cart/cart-context";
import Image from "../../shared/utilities/Image";

function SingleCartItem({ item }) {
  const { deleteCartItem, editCartItem, toggleCheck } = useContext(CartContext);

  const [localQuantity, setLocalQuantity] = useState(0);
  useEffect(() => {
    setLocalQuantity(item.quantity);
  }, [item]);

  useEffect(() => {
    let timeout = setTimeout(quantityChanged, 300);
    return () => clearTimeout(timeout);
  }, [localQuantity]);

  function quantityChanged() {
    if (localQuantity !== 0) {
      const newItem = { ...item };
      newItem.newQuantity = +localQuantity;
      editCartItem(newItem);
    }
  }

  function inputChanged(e) {
    if (e.target.value) setLocalQuantity(e.target.value);
  }
  
  return (
    <div className="cart__item" data-id={item.productId}>
      <div className="cart__item-wrapper">
        <div className="cart__item-input">
          <input type="checkbox" checked={item.checked} onChange={() => toggleCheck(item)} />
        </div>
        <div className="cart__item-image ">
          <Image src={item.productImageUrl} alt={item.productName} />
        </div>
        <div className="cart__item-detail">
          <NavLink
            className="cart__item-product-name"
            to={`/products/${item.productId}`}
          >
           {item.productName}
          </NavLink>
          <div className="cart__item-options">
            <label className="cart__item-label">QTY: </label>
            <input
              type="number"
              className="cart__item-quantity"
              min={1}
              required
              value={localQuantity}
              onChange={inputChanged}
            />
            <p className="cart__item-options-wrapper" onClick={() => deleteCartItem(item)}>
              <span className="cart__item-divider">|</span>
              <span className="cart__item-delete">Delete</span>
              <span className="cart__item-divider">|</span>
            </p>
          </div>
        </div>
        <div className="cart__item-price">${item.price}</div>
      </div>
    </div>
  );
}

export default SingleCartItem;
