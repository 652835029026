import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
// import api from "../../api";
import PaymentContext from "./payment-context";

function PaymentState({ children }) {
  const [paymentMethods, setPaymentMethods] = useState([]);
  // which payment method is currently selected
  // -1 is for add new Payment Method
  const [selectedMethodIndex, setSelectedMethodIndex] = useState(-1);

  // selected payment method in checkout page
  const [checkoutSelectedMethodId, setCheckoutSelectedMethodId] = useState(-1);

  async function fetchPaymentMethods() {
    setCheckoutSelectedMethodId(-1);
    setSelectedMethodIndex(-1);

    const result = await api.get("/payment");
    setPaymentMethods(result.data);
    result.data.length && setCheckoutSelectedMethodId(result.data[0].id);
  }

  async function addPaymentMethod(payment) {
    try {
      const toBeAdded = refinePayment(payment);
      const result = await api.post("/payment", toBeAdded);

      setPaymentMethods((payments) => {
        return [result.data, ...payments];
      });

      setCheckoutSelectedMethodId(result.data.id);
      setSelectedMethodIndex(0);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 400) {
        toast.error(error.response.data.message, {
          position: "bottom-right",
          draggable: true,
        });
      }
      throw error;
    }
  }

  async function removePaymentMethod(payment) {
    try {
      setSelectedMethodIndex(-1);
      checkoutSelectedMethodId === payment.id && setCheckoutSelectedMethodId(-1);

      await api.delete(`/payment/${payment.id}`);
      const items = paymentMethods.filter((item) => item.id !== payment.id);
      setPaymentMethods(items);
      setCorrectSelectedPaymentMethod(payment);
    } catch (error) {
      console.log(error);
    }
  }

  async function updatePaymentMethod(payment) {
    const toBeAdded = refinePayment(payment);

    try {
      const result = await api.put(`/payment/${payment.id}`, toBeAdded);
      setPaymentMethods((payments) => {
        return payments.map((item) => (item.id === result.data.id ? result.data : item));
      });
    } catch (error) {
      console.log(error);

      if (error?.response?.status === 400) {
        toast.error(error.response.data.message, {
          position: "bottom-right",
          draggable: true,
        });
      }

      throw error;
    }
  }
  // after a payment method is deleted
  // make sure to sit proper payment method as selected
  function setCorrectSelectedPaymentMethod(payment) {
    setSelectedMethodIndex(-1);
    if (payment.id !== checkoutSelectedMethodId) return;

    // because react doesn't set state in synchronise way
    // we have to loop and find the first item that isn't the deleted item
    // and set it as selected
    for (let i = 0; i < paymentMethods.length; i++) {
      if (payment.id !== paymentMethods[i].id) return setCheckoutSelectedMethodId(paymentMethods[i].id);
    }

    setCheckoutSelectedMethodId(-1);
  }

  // utitlites
  function refinePayment(payment) {
    return {
      cardNumber: payment.cardNumber,
      expirationMonth: +payment.expirationMonth,
      expirationYear: +payment.expirationYear,
      nameOnCard: payment.nameOnCard,
      securityCode: payment.securityCode + "",
    };
  }

  return (
    <PaymentContext.Provider
      value={{
        checkoutSelectedMethodId,
        setCheckoutSelectedMethodId,
        selectedMethodIndex,
        setSelectedMethodIndex,
        paymentMethods,
        setPaymentMethods,
        fetchPaymentMethods,
        addPaymentMethod,
        removePaymentMethod,
        updatePaymentMethod,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
}

export default PaymentState;
