import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import AuthContext from "../../../contexts/shared/auth/auth-context";
import { Roles } from "../../../constants";
import Label from "../../common/Label";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is a required field"),
  password: Yup.string().required("Password is a required field"),
});

const initialValues = {
  email: "",
  password: "",
};
function Login() {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  async function formSubmitted(values, { setSubmitting, setStatus }) {
    try {
      setSubmitting(true);
      const result = await login(values);
      if (result.role === Roles.Customer) {
        navigate("/");
      } else if (result.role === Roles.Company) {
        navigate("/company/dashboard");
      }
    } catch (error) {
      if (error.response.status === 400) setStatus(error.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <div className="login">
      <div className="login__layout">
        <h1 className="login__title">TEKSCHOOL</h1>
        <Formik initialValues={initialValues} validationSchema={LoginSchema} onSubmit={formSubmitted}>
          {({ isSubmitting, isValid, dirty, status }) => (
            <Form className="login__form" id="loginForm">
              {/* Title */}
              <h1 className="login__subtitle">Sign in</h1>

              {/* Email */}
              <div className="login__input-wrapper">
                <Label title="Email" required />
                <Field type="email" id="email" name="email" className="login__input" />
                <ErrorMessage name="email" id="emailError" component="div" className="error" />
              </div>

              {/* Password */}
              <div className="login__input-wrapper">
                <Label title="Password" required />
                <Field type="password" id="password" name="password" className="login__input" />
                <ErrorMessage name="password" id="passwordError" component="div" className="error" />
              </div>

              {/* Submit Button */}
              <div className="login__input-wrapper">
                <button
                  type="submit"
                  className={`w-full  ${
                    isSubmitting || !isValid || !dirty ? "login__invalid" : "login__valid"
                  } login__submit`}
                  disabled={isSubmitting || !isValid || !dirty}
                  id="loginBtn"
                >
                  Login
                </button>
              </div>
              {status && <div className="error">{status}</div>}
            </Form>
          )}
        </Formik>
        <p>Or</p>
        
        <NavLink className="login__account-btn" to={"/auth/sign-up"} id="newAccountBtn">
        Create New Account
        </NavLink>
        <NavLink className="login__company-btn" to={"/auth/sign-up/seller"} id="newCompanyAccount">
          want to sell something?
        </NavLink>
        {/* <button id="newCompanyAccountBtn" className="login__company-btn" onClick={() => navigate("/auth/sign-up/seller")}>
          want to sell something?
        </button> */}
      </div>
    </div>
  );
}

export default Login;
