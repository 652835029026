import React from 'react'

const baseURL = process.env.REACT_APP_API_URL;

function Image({src, onClick, alt , ...rest}) {
  
  return (
    <img
    src={`${
      src.startsWith("http") || src.startsWith("data:image")
        ? `${src}` 
        : `${baseURL}${src}`
    }`}
    className="image"
    onClick={onClick}
    alt={alt}
    {...rest}
  />

  )
}

export default Image