import React, { useState } from "react";
import Image from "../shared/utilities/Image";
import Label from "../common/Label";
import Title from "./Title";


function UploadFile() {
  const [image, setImage] = useState(null);

  const saveFile = (e) => {
    const reader = new FileReader();
    const files = e.target.files;
    const file = files[0];

    // Get the file object after upload and read the
    // data as URL binary string
    reader.readAsDataURL(file);

    // Once loaded, do something with the string
    reader.addEventListener("load", (event) => {
      setImage(event.target.result);
    });
  };

  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Image Upload" />
      <div className="flex flex-col gap-3">
        <Label title="Select an Image to upload" />
        <input type={"file"} id="fileInput" name="image" onChange={saveFile} accept="image/*"/>
      </div>
      <div>
        <div className="w-40">{image && <Image src={image} id="image" />}</div>
      </div>
    </div>
  );
}

export default UploadFile;
