import React, { useState } from "react";
import Payments from "./Payments";
import { HiPlus } from "react-icons/hi";
import Modal from "../../../shared/utilities/Modal";
import NewPayment from "../../payment/NewPayment";

function Index() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className="checkout__payment">
        <h1 className="checkout__payment-title">Your Debit and Credit Cards</h1>

        <div className="checkout__payment-header">
          <div className="checkout__payment-header-empty"></div>
          <div className="checkout__payment-header-column">Name on Card</div>
          <div className="checkout__payment-header-column">Expires On</div>
        </div>
        <hr className="checkout__line" />
        <Payments />

        <button
          className="checkout__payment-btn"
          id="addPaymentBtn"
          onClick={() => setShowModal(true)}
        >
          <HiPlus className="checkout__payment-icon" /> <img src="/images/master_card.png" alt="master card" className="checkout__payment-image" /> Add a Credit or Debit Card
        </button>
      </div>

      <Modal show={showModal} setShow={setShowModal} options={{ closeButton: true }}  className="checkout__modal">
        <NewPayment setShow={setShowModal} />
      </Modal>
    </>
  );
}

export default Index;
