import React, { useEffect, useState } from "react";
import Title from "./Title";

const getRandomColor = () => {
  const h = Math.floor(Math.random() * 360);

  return `hsl(${h}deg, 90%, 85%)`;
};

function InfiniteScroll() {
  // Ref: https://webdesign.tutsplus.com/tutorials/how-to-implement-infinite-scrolling-with-javascript--cms-37055
  const [cards, setCards] = useState([
    { id: 1, background: getRandomColor() },
    { id: 2, background: getRandomColor() },
    { id: 3, background: getRandomColor() },
    { id: 4, background: getRandomColor() },
    { id: 5, background: getRandomColor() },
    { id: 6, background: getRandomColor() },
    { id: 7, background: getRandomColor() },
    { id: 8, background: getRandomColor() },
    { id: 9, background: getRandomColor() },
    { id: 10, background: getRandomColor() },
    { id: 11, background: getRandomColor() },
    { id: 12, background: getRandomColor() },
    { id: 13, background: getRandomColor() },
    { id: 14, background: getRandomColor() },
    { id: 15, background: getRandomColor() },
    { id: 16, background: getRandomColor() },
  ]);

  useEffect(() => {
    function handleInfiniteScroll() {
      const endOfPage = window.innerHeight + window.pageYOffset >= document.body.offsetHeight;
      function addCards() {
        // #ToDo Please clean up this part.
        setTimeout(() => {
          setCards((cards) => {
            if (cards.length >= 128) return cards;
            const newCards = new Array(16).fill(0).map((item, index) => ({
              id: cards.length + 1 + index,
              background: getRandomColor(),
            }));
            return [...cards, ...newCards];
          });
        }, 500);
      }

      if (endOfPage) {
        addCards();
      }
    }
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);

  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Infinite Scroll" id="title" />
        <p>Infinite scrolling is a listing-page design approach which loads content continuously as the user scrolls down. It eliminates the need for pagination — breaking content up into multiple pages</p>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        {cards.map((item) => (
          <p
            className="p-16 text-center text-lg font-semibold shadow rounded"
            style={{ background: item.background }}
            key={item.id}
          >
            {item.id}
          </p>
        ))}
      </div>
      <div>{cards && <p className="font-bold text-lg"> showing {cards.length} out of 128 cards</p>}</div>
    </div>
  );
}

export default InfiniteScroll;
