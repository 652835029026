import React, { useContext, useEffect, useState } from "react";
import CartContext from "../cart/cart-context";
import RecentlyViewedItemsContext from "./recently-viewed-items-context";
function RecentlyViewedItemsState({ children }) {
  const [recentlyViewedItems, setRecentlyViewedItems] = useState([]);
  const { cartItemIndex, cartItems } = useContext(CartContext);

  useEffect(() => {
    purifyItems();
  }, [cartItems]);

  function purifyItems() {
    // if an items exists in cart remove it from recentlyviewedItems

    const newItems = recentlyViewedItems.filter(
      (item) => cartItemIndex(item.id) < 0
    );

    setRecentlyViewedItems(newItems);
    updateRecentlyViewedItems(newItems);
  }

  function addRecentlyViewedItem(item) {
    // do not add an item that exists in cart or is already in the viewed list
    if (itemExists(item) || cartItemIndex(item.id) >= 0) {
      return;
    }
    const temp = [...recentlyViewedItems];
    // store only last four items
    if (temp.length >= 4) {
      temp.pop();
    }
    temp.unshift(item);
    setRecentlyViewedItems(temp);
    updateRecentlyViewedItems(temp);
  }

  function fetchRecentlyViewedItems() {
    const items = JSON.parse(localStorage.getItem("recentlyViewedItems"));
    while (items && items.length > 4) {
      items.pop();
    }
    if (items) setRecentlyViewedItems(items);
  }
  function removeRecentlyViewedItem(removeItem) {
    const items = recentlyViewedItems.filter(
      (item) => item.id !== removeItem.id
    );
    setRecentlyViewedItems(items);
    updateRecentlyViewedItems(items);
  }
  function updateRecentlyViewedItems(items) {
    // update them locally
    localStorage.removeItem("recentlyViewedItems");
    localStorage.setItem("recentlyViewedItems", JSON.stringify(items));
  }

  // Utilities
  function itemExists(checkItem) {
    return recentlyViewedItems &&
      recentlyViewedItems.findIndex((item) => item.id == checkItem.id) >= 0
      ? true
      : false;
  }

  return (
    <RecentlyViewedItemsContext.Provider
      value={{
        recentlyViewedItems,
        addRecentlyViewedItem,
        fetchRecentlyViewedItems,
        removeRecentlyViewedItem,
      }}
    >
      {children}
    </RecentlyViewedItemsContext.Provider>
  );
}

export default RecentlyViewedItemsState;
