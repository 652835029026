import React, { useState } from "react";
import Title from "./Title";

function ChallengingDom() {
  const [data, setData] = useState({
    titles: ["Company", "Contact", "Country", "Actions"],
    rows: [
      { id: 1, company: "Alfreds Futterkiste", contact: "Maria Anders", country: "Germany" },
      { id: 2, company: "Centro comercial Moctezuma", contact: "Francisco Chang", country: "Mexico" },
      { id: 3, company: "Ernst Handel", contact: "Roland Mendel", country: "Austria" },
      { id: 4, company: "Island Trading", contact: "Helen Bennett", country: "UK" },
      { id: 5, company: "Laughing Bacchus Winecellars", contact: "Yoshi Tannamuri", country: "Canada" },
      { id: 6, company: "Magazzini Alimentari Riuniti", contact: "Giovanni Rovelli", country: "Mexico" },
    ],
  });
  
  const [editId, setEditId] = useState(-1);

  const [localCompany , setLocalCompany] = useState("");
  const [localContact , setLocalContact] = useState("");
  const [localCountry, setLocalCountry] = useState("");

  function editClicked(id){
    setEditId(id);
    const item = data.rows.find(item=> item.id===id);
    setLocalCompany(item.company);
    setLocalContact(item.contact);
    setLocalCountry(item.country);
    
  }
  function resetLocals(){
    setEditId(-1);
    setLocalCompany("");
    setLocalContact("");
    setLocalCountry("");
  }
  function save(){
    const items = data.rows.map(item=> item.id === editId ? {id: item.id , company: localCompany , contact: localContact, country: localCountry}: item );
    setData(data=>({...data , rows: items}));
    resetLocals();
  }
  function remove(id) {
    setData((data) => {
      return {
        ...data,
        rows: data.rows.filter((item) => item.id !== +id),
      };
    });
  }
  return (
    <div className="selenium__challenging-dom">
      <Title title="Challenging DOM" id="title" />

      <table>
        <thead>
          <tr>
            {data.titles.map((item) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.rows.map((item) => (
            <tr key={item.id}>
              <td>{editId === item.id ? <p><input type="text" value={localCompany} onChange={(e)=>setLocalCompany(e.target.value)} /> </p> : <p>{item.company}</p>}</td>
              <td>{editId === item.id ? <p><input type="text" value={localContact} onChange={(e)=>setLocalContact(e.target.value)} /> </p> : <p>{item.contact}</p>}</td>
              <td>{editId === item.id ? <p><input type="text" value={localCountry} onChange={(e)=>setLocalCountry(e.target.value)} /> </p> : <p>{item.country}</p>}</td>
              <td>
                { editId === item.id ? <button onClick={save}>Save</button> :<button onClick={() => editClicked(item.id)}>Edit </button>}
                { editId === item.id ? <button onClick={resetLocals}>Cancel</button>:<button onClick={() => remove(item.id)}> Delete </button>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ChallengingDom;
