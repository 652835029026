import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";

import AuthContext from "../../../contexts/shared/auth/auth-context";
import CartContext from "../../../contexts/customer/cart/cart-context";
import RecentlyViewedItemsContext from "../../../contexts/customer/recently-viewed-items/recently-viewed-items-context";
import Star from "../../shared/utilities/Star";
import Image from "../../shared/utilities/Image";
const selectArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function SingleViewedItem({ item }) {
  const [quantity, setQuantity] = useState(1);

  const { addCartItem } = useContext(CartContext);
  const { removeRecentlyViewedItem } = useContext(RecentlyViewedItemsContext);
  const { user } = useContext(AuthContext);

  function addCartItemClicked(item) {
    if (user) {
      const cartItem = {
        roleId: user.roleId,
        productId: item.id,
        productName: item.name,
        productImageUrl: item.imageUrl,
        price: item.price,
        quantity,
      };
      addCartItem(cartItem);
      removeRecentlyViewedItem(item);
    }
  }
  return (
    <div className="cart__recent-item" data-id={item.id}>
      <div className="cart__recent-item-image">
        <Image src={item.imageUrl} alt={item.name} />
      </div>
      <div className="cart__recent-item-detail">
        <NavLink className="cart__recent-item-title" to={`/products/${item.id}`}>
          {item.name}
        </NavLink>
        <Star rating={item.rating} />
        <p className="cart__recent-item-price">$ {item.price}</p>
        <div className="cart__recent-item-quantity">
          <label className="cart__recent-item-label">QTY: </label>
          <select
            id={`select-${item.id}`}
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            className="cart__recent-item-select"
          >
            {selectArray.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <button id="addToCartBtn" className="cart__recent-item-btn" onClick={() => addCartItemClicked(item)}>
          Add to Cart
        </button>
      </div>
    </div>
  );
}

export default SingleViewedItem;
