import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import AuthContext from "../../../contexts/shared/auth/auth-context";
import Label from "../../common/Label";

const urlRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const SignupSchema = Yup.object().shape({
  name: Yup.string().min(3).required(),
  email: Yup.string().email("Invalid email").required(),
  password: Yup.string().required(),
  description: Yup.string().required(),
  url: Yup.string().matches(urlRegex, "URL is not valid"),
});

const initialValues = {
  name: "",
  email: "",
  password: "",
  description: "",
  url: "",
};

function SignupSeller() {
  const { createSellerAccount } = useContext(AuthContext);
  const navigate = useNavigate();

  async function formSubmitted(values, { setSubmitting, setStatus }) {
    try {
      setSubmitting(true);
      await createSellerAccount(values);
      navigate("/company/dashboard");
    } catch (error) {
      if (error.response.status === 400) setStatus(error.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="signup">
      <div className="signup__layout">
        <h1 className="signup__title">TEKSCHOOL</h1>
        <Formik initialValues={initialValues} validationSchema={SignupSchema} onSubmit={formSubmitted}>
          {({ isSubmitting, isValid, dirty, status }) => (
            <Form className="signup__form" id="sellerForm">
              {/* Title */}
              <h1 className="signup__subtitle">Create Account</h1>

              {/* Email */}
              <div className="signup__input-wrapper">
                <Label title="Email" required />
                <Field type="email" id="email" name="email" className="signup__input" />
                <ErrorMessage name="email" id="emailError" component="div" className="error" />
              </div>

              {/* Password */}
              <div className="signup__input-wrapper">
                <Label title="Password" required />
                <Field type="password" id="password" name="password" className="signup__input" />
                <ErrorMessage name="password" id="passwordError" component="div" className="error" />
              </div>

              {/* Name */}
              <div className="signup__input-wrapper">
                <Label title="Company Name" required />
                <Field type="text" name="name" id="nameInput" className="signup__input" />
                <ErrorMessage name="name" id="nameError" component="div" className="error" />
              </div>

              {/* Description */}
              <div className="signup__input-wrapper">
                <Label title="Description" required />
                <Field as="textarea" rows={2} name="description" className="signup__input" id="descriptionInput" />
                <ErrorMessage name="description" id="descriptionError" component="div" className="error" />
              </div>

              {/* URL */}
              <div className="signup__input-wrapper ">
                <Label title="url" />
                <Field type="text" name="url" id="urlInput" className="signup__input" />
                <ErrorMessage name="url" id="urlError" component="div" className="error" />
              </div>
              {/* Submit Button */}
              <div className="signup__input-wrapper ">
                <button
                  type="submit"
                  className={` ${isSubmitting || !isValid || !dirty ? "signup__invalid" : "signup__valid"} signup__btn`}
                  disabled={isSubmitting || !isValid || !dirty}
                  id="signupAsSellerBtn"
                >
                  Create Account
                </button>
              </div>
              {status && <div className="error">{status}</div>}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default SignupSeller;
