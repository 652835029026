import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {HiPencil , HiOutlineTrash} from 'react-icons/hi';

import Image from "../../shared/utilities/Image";
import Star from "../../shared/utilities/Star";
import CompanyProductContext from "../../../contexts/company/products/company-product-context";

function SingleCompanyProduct({ product }) {
  const navigate = useNavigate();

  const {deleteProduct} = useContext(CompanyProductContext)


  function editProduct(){
    navigate(`/company/product/edit/${product.id}`);
  }

  return (
    <div className="border shadow flex">
      <div className="w-6/12">
        <Image
          src={product.imageUrl}
          onClick={() => navigate(`/products/${product.id}`)}
          alt={product.name}
        />
      </div>

      <div className="w-6/12 relative">
        <p
          className="p-1 cursor-pointer hover:text-amber-700"
          onClick={() => navigate(`/products/${product.id}`)}
        >
          {product.name}
        </p>
        <p className="p-1 text-xs cursor-pointer">
          ${product.price}
        </p>
        <Star rating={product.rating} />
        <div className="absolute bottom-0 right-0 flex w-full justify-center pb-1">
            <HiPencil className="cursor-pointer hover:text-blue-600" onClick={editProduct} />
            <HiOutlineTrash className="cursor-pointer hover:text-red-600" onClick={()=>deleteProduct(product.id)}/>
        </div>
      </div>
    </div>
  );
}

export default SingleCompanyProduct;
