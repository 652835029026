import React, { useContext } from "react";
import PaymentContext from "../../../../contexts/customer/payment/payment-context";
import PaymentImage from "../../payment/PaymentImage";
import PaymentTitle from "../../payment/PaymentTitle";

function Payment({ payment }) {
  const { checkoutSelectedMethodId, setCheckoutSelectedMethodId } = useContext(PaymentContext);

  return (
    <div
      className={`${
        checkoutSelectedMethodId === payment.id && "checkout__payment-item-selected"
      } checkout__payment-item`}
      onClick={() => setCheckoutSelectedMethodId(payment.id)}
      data-id={payment.id}
    >
      <div className="checkout__payment-item-column-image">
        <input
          type="radio"
          checked={checkoutSelectedMethodId === payment.id}
          onChange={() => setCheckoutSelectedMethodId(payment.id)}
        />

        <PaymentImage cardNumber={payment.cardNumber} className="checkout__payment-item-image" />
        <div>
          <PaymentTitle cardNumber={payment.cardNumber} className="checkout__payment-item-title" />
          <p className="text-xs"> Ending in .... {payment.cardNumber.substr(-4)}</p>
        </div>
      </div>
      <div className="checkout__payment-item-column ">{payment.nameOnCard}</div>
      <div className="checkout__payment-item-column ">
        {payment.expirationMonth}/{payment.expirationYear}
      </div>
    </div>
  );
}

export default Payment;
