import React, { useState } from "react";
import Addresses from "./Addresses";
import { HiPlus } from "react-icons/hi";
import Modal from "../../../shared/utilities/Modal";
import NewAddress from "../../address/NewAddress";
function Index() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className="checkout__address">
        <h1 className="checkout__address-title">Your Addresses</h1>
        <hr className="checkout__line" />
        <Addresses />
        <button
          className="checkout__address-btn"
          onClick={() => setShowModal(true)}
          id="addAddressBtn"
        >
          <HiPlus className="checkout__address-icon" /> Add a new address
        </button>
      </div>

      <Modal show={showModal} setShow={setShowModal} options={{ closeButton: true }}  className="checkout__modal">
        <NewAddress setShow={setShowModal} />
      </Modal>
    </>
  );
}

export default Index;
