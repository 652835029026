import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CompanyProducts from "../product/CompanyProducts";
import CompanyProductContext from "../../../contexts/company/products/company-product-context";

function Dashboard() {
  
  
  const {products} = useContext(CompanyProductContext)
  const navigate = useNavigate();

  return (
    <div>
      <div className="p-4">
        <div className="flex justify-between border-b border-gray-400 py-2">
          <h1 className="text-xl font-bold">All Products</h1>
          <button
            className="py-1 px-2 bg-yellow-600 text-white rounded"
            onClick={() => navigate("/company/product/new")}
          >
            Add
          </button>
        </div>
        <div>
          {products.length == 0 && (
            <div className="w-full py-4">
              <div className="shadow md:w-3/4 bg-white">
                <h1 className="text-3xl p-4">Currently You have no products</h1>
                <p className="px-4 pb-4">
                  You can use the <strong>add</strong> button on top right
                  corner to add a new product.
                </p>
              </div>
            </div>
          )}
          <div className="flex">
            <div className="w-3/4">
              {products && <CompanyProducts products={products} />}
            </div>
            <div className="2-1/4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
