import React from "react";
import { useLocation } from "react-router-dom";
import { HiCheckCircle } from "react-icons/hi";

function ReturnConfirm() {
  const { state } = useLocation();

  return (
    <div className="return__confirm">
      <div className="return__confirm-card ">
        <div className="return__confirm-message">
          <HiCheckCircle /> <p>Return was successfull</p>
        </div>
        <p>Your confirmation number is {state.number}.</p>
      </div>
    </div>
  );
}

export default ReturnConfirm;
