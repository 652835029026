import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ProductContext from "../../../contexts/customer/products/product-context";
import SidebarContext from "../../../contexts/customer/sidebar/sidebar-context";
import Dropdown from "../utilities/Dropdown";
import SearchInput from "../utilities/search/SearchInput";
import api from "../../../contexts/api";

function Search() {
  const { productNames, setProducts } = useContext(ProductContext);
  const { categories } = useContext(SidebarContext);
  const [firstLevelCategories, setFirstLevelCategories] = useState([]);
  const [selectedCategoryId, setSelectCategoryId] = useState(-1);
  useEffect(() => {
    const temp = Object.keys(categories).map((key, index) => ({
      id: categories[key].id,
      title: categories[key].title,
    }));
    
    const firstLevelItems = [{id: -1 , title: "All departments"}, ...temp];
    setFirstLevelCategories(firstLevelItems);
  }, [categories]);
  const navigate = useNavigate();

  async function search(item) {
    const result = await api.get(
      `/product?name=${item}${+selectedCategoryId !== -1 ? `&parentCategoryId=${selectedCategoryId}` : ""
      }`
    );
    setProducts(result.data);
    navigate("/products");
  }

  return (
    <div className="search">
      <Dropdown items={firstLevelCategories} select={setSelectCategoryId} />
      <SearchInput className="grow" items={productNames} search={search} selectedCategoryId={selectedCategoryId} />
    </div>
  );
}

export default Search;
