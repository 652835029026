import React, { useEffect, useState } from "react";
import api from "../../../../contexts/api";
import Skeleton from "../../../common/Skeleton";
import SingleReview from "./SingleReview";

function Reviews({ productId }) {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchReviews();
  }, [productId]);

  async function fetchReviews() {
    try {
      const result = await api.get(`/review/product/${productId}`);
      setReviews(result.data);
    } catch (error) {
    } finally {
      setTimeout(()=>{
        setLoading(false);
      },100);
    }
  }

  return loading ? (
    <Skeleton />
  ) : (
    <div className="reviews">
      {reviews.length ? reviews.map((item) => (
        <SingleReview key={item.id} review={item} />
      )): <div>
        There are no user reviews yet
        </div>}
    </div>
  );
}

export default Reviews;
