import React, { useEffect, useState } from "react";
import { FaStarHalfAlt, FaStar, FaRegStar } from "react-icons/fa";

function Star({ rating }) {
  const [refinedRating, setRefinedRating] = useState(0);
  const [showRatingBox,setShowRatingBox] = useState(false);
  useEffect(() => {
    const intRating = +parseInt(rating + "");
    if (rating - intRating >= 0.75) {
      // 4.8 becomes 5
      setRefinedRating(intRating + 1);
    } else if (rating - intRating >= 0.5) {
      // 4.6 becomes 4.5
      setRefinedRating(intRating + 0.5);
    } else if (rating - intRating >= 0.25) {
      // 4.3 becomes 4.5
      setRefinedRating(intRating + 0.5);
    } else {
      // 4.1 becomes 4
      setRefinedRating(intRating);
    }
  }, [rating]);

  return (
    <div className="star">
      <div className="star__layout">
        {[...Array(5)].map((item, index) => {
          return (
            <p className="star__text" key={index} onMouseOver={()=>setShowRatingBox(true)} onMouseLeave={()=>setShowRatingBox(false)}>
              {refinedRating > index && refinedRating < index + 1 ? (
                <FaStarHalfAlt className="star__icon" />
              ) : refinedRating > index ? (
                <FaStar className="star__icon" />
              ) : (
                <FaRegStar className="star__icon" />
              )}
            </p>
          );
        })}
      </div>
      {showRatingBox && <div className="star__box">
        <p className="star__rating">{rating} out of 5</p>
      </div>}
    </div>
  );
}

export default Star;
