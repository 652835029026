import React from "react";
import SingleCompanyProduct from "./SingleCompanyProduct";
function CompanyProducts({ products }) {
  

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 py-3 px-2 bg-gray-100">
      {products.map((item) => (
        <SingleCompanyProduct product={item} key={item.id} />
      ))}
    </div>
  );
}

export default CompanyProducts;
