import React from "react";
import AddressDetail from "./AddressDetail";

function Header({ show, setShow, order }) {

  return (
    <div className="order__header">
      <div className="order__header-details">
        {/* Left */}
        <div>
          <p className="order__header-title">Order Placed</p>
          <p>{new Date(order.date).toLocaleDateString("en-us", { day: "numeric", year: "numeric", month: "short" })}</p>
        </div>
        <div>
          <p className="order__header-title">Total</p>
          <p>${order.price}</p>
        </div>
        <div>
          <p className="order__header-title">Ship To</p>
          <AddressDetail order={order} />
        </div>
      </div>
      <div className="order__header-number">
        <div>
          <p className="order__header-title">Order # {order.number}</p>
          <p className="order__header-btn" onClick={() => setShow(!show)}>
            {show ? "Hide Details" : "Show Details"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Header;
