import React, { useState } from "react";
import Title from "./Title";

function JavascriptAlerts() {
  const [message, setMessage] = useState("");
  function alertClicked() {
    alert("You have clicked on alert btn");
    setMessage("You successfully clicked on alert");
  }
  function confirmClicked() {
    if (window.confirm("Are you sure about that?")) {
      setMessage("You have confirmed");
    } else {
      setMessage("You did not confirm");
    }
  }
  function promptClicked() {
    const text = prompt("Enter something");
    setMessage(`You wrote ${text}`);
  }
  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Javascript Alerts" />
      <div>
        <button
          id="alertBtn"
          className="bg-blue-800 text-blue-100 py-2 px-3 rounded m-2 hover:bg-blue-700"
          onClick={alertClicked}
        >
          Alert
        </button>
        <button
          id="confirmBtn"
          className="bg-blue-800 text-blue-100 py-2 px-3 rounded m-2 hover:bg-blue-700"
          onClick={confirmClicked}
        >
          Confirm
        </button>
        <button
          id="promptBtn"
          className="bg-blue-800 text-blue-100 py-2 px-3 rounded m-2 hover:bg-blue-700"
          onClick={promptClicked}
        >
          Prompt
        </button>
      </div>
      <div id="message" className="text-green-500">
        {message}
      </div>
    </div>
  );
}

export default JavascriptAlerts;
