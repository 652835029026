import React, { useContext } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import AuthContext from "../../../contexts/shared/auth/auth-context";
import Label from "../../common/Label";
import { nameValidation } from "../../../validations";

// yup normally shows one error message at a time
// below method let us show multiple at once . required for password
const asyncValidateSchema = (schema) => (values) =>
  schema
    .validate(values, { abortEarly: false, strict: false })
    .then(() => ({}))
    .catch(({ inner }) =>
      inner.reduce((memo, { path, message }) => {
        // for email only show one error message
        if (path === "email" ) {
          return { ...memo, [path]: message };
        }
        // if the input is blank show confirm password is required message
        // instead of passwords must match error message (I am not proud of what i have done here . sorry :( 
        // it was the user requirement )
        if (path === "confirmPassword" || path==="name" ) {
          if (memo[path] && memo[path].length) return { ...memo, [path]: memo[path] };
          return { ...memo, [path]: message };
        }
        
        if(path==="password" && !values.password){
          return {...memo, [path]: "Password is a required field"}
        }
        
        return { ...memo, [path]: (memo[path] || []).concat(message) };
      }, {})
    );

const SignupSchema = Yup.object().shape(
  {
    name: Yup.string().required("Name is a required field").min(3).max(30).matches(nameValidation.regex, nameValidation.message),
    email: Yup.string()
      .max(30)
      // only characters numbers - _ . are allowed before @sign.
      // one @sign is allowed
      // domain part can only be alphabets
      .matches(/^[A-Za-z0-9-_]+(\.[A-Za-z0-9-_]+)*@[A-Za-z]+(\.[A-Za-z]+)*$/, "please enter valid email")
      .email("please enter valid email")
      .required("Email is a required field")
      // avoid duplicate subdomain part
      // Ex: .com.com is wrong or .test.test
      .test("email1", "please enter valid email", (value) => !/@.*(\.[A-Za-z]+)\1/g.test(value))
      //.com.net is wrong
      // Ref for test function https://stackoverflow.com/questions/71767426/yup-validation-not-work-when-use-not-in-matches-function
      .test("email3", "please enter valid email", (value) => !(/(\.com|\.net|\.org|\.co|\.edu|\.us|\.me|\.cn|\.af)(\..*)+$/.test(value)))
      .test("email4", "you can use atmost 3 subdomains", (value) => !/@.*(\.[A-Za-z]+){3,}/.test(value)),
    password: Yup.string()
      .required("Password is a required field")
      .min(8, "at least 8 characters\n")
      .max(30, "at most 30 characters\n")
      .matches(/[a-z]/, "at least one lowercase char\n")
      .matches(/[A-Z]/, "at least one uppercase char\n")
      .matches(/[0-9]+/, "at least one number\n")
      .matches(/[a-zA-Z0-9]+[^a-zA-Z0-9\s]+/, "at least one special char\n"),
    confirmPassword: Yup.string()
      .required("Confirm Password is a required field")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  },
  { abortEarly: false }
);

const initialValues = {
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
};

function Signup() {
  const { signup } = useContext(AuthContext);
  const navigate = useNavigate();

  async function formSubmitted(values, { setSubmitting, setStatus }) {
    try {
      setSubmitting(true);
      await signup(values);
      navigate("/profile");
    } catch (error) {
      if (error.response.status === 400) setStatus(error.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="signup">
      <div className="signup__layout">
        <h1 className="signup__title">TEKSCHOOL</h1>

        <Formik initialValues={initialValues} validate={asyncValidateSchema(SignupSchema)} onSubmit={formSubmitted}>
          {({ isSubmitting, isValid, dirty, status }) => (
            <Form className="signup__form" id="signUpForm">
              {/* Title */}
              <h1 className="signup__subtitle">Sign Up</h1>

              {/* Name */}
              <div className="signup__input-wrapper">
                <Label title="Name" required />
                <Field type="text" name="name" id="nameInput" className="signup__input" />
                <ErrorMessage name="name" id="nameError" component="div" className="error" />
              </div>

              {/* Email */}
              <div className="signup__input-wrapper">
                <Label title="Email" required />
                <Field type="email" name="email" id="emailInput" className="signup__input" />
                <ErrorMessage name="email" id="emailError" component="div" className="error" />
              </div>

              {/* Password */}
              <div className="signup__input-wrapper">
                <Label title="Password" required />
                <Field type="password" name="password" id="passwordInput" className="signup__input" />
                <ErrorMessage name="password" id="passwordError" component="pre" className="error" />
              </div>
              {/*Confirm Password */}
              <div className="signup__input-wrapper">
                <Label title="Confirm Password" required />
                <Field type="password" name="confirmPassword" id="confirmPasswordInput" className="signup__input" />
                <ErrorMessage name="confirmPassword" id="confirmPasswordError" component="div" className="error" />
              </div>

              {/* Submit Button */}
              <div className="signup__input-wrapper">
                <button id="signupBtn" type="submit" className={`signup__btn`}>
                  Sign Up
                </button>
              </div>
              {status && <div className="error">{status}</div>}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Signup;
