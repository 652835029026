import React, { useContext } from "react";

import PaymentContext from "../../../../contexts/customer/payment/payment-context";
import Payment from "./Payment";

function Payments() {
  const { paymentMethods } = useContext(PaymentContext);

  return (
    <>
      {paymentMethods.map((item) => (
        <Payment payment={item} key={item.id}  />
      ))}
    </>
  );
}

export default Payments;
