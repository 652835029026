import React, { useState } from "react";

import ProductContext from "./product-context";
import api from "../../api";

function ProductState({ children }) {
  const [products, setProducts] = useState([]);
  const [productNames, setProductNames] = useState([]);

  async function fetchProductNames(){
     const result = await api.get("/product/names");
     setProductNames(result.data);
  }

  async function fetchProducts() {
    const result = await api.get("/product");
    setProducts(result.data);
  }

  return (
    <ProductContext.Provider
      value={{ products, setProducts, productNames, setProductNames , fetchProductNames , fetchProducts }}
    >
      {children}
    </ProductContext.Provider>
  );
}

export default ProductState;
