import React, { useContext } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { toast } from "react-toastify";

import Label from "../../common/Label";
import AddressContext from "../../../contexts/customer/address/address-context";
import { addressValidation, cityValidation, nameValidation, phoneNumberValidation, zipCodeValidation } from "../../../validations";
const addressSchema = Yup.object().shape({
  country: Yup.string().required("Country is a required field"),
  fullName: Yup.string().required("Full Name is a required field").max(30).matches(nameValidation.regex,nameValidation.message),
  phoneNumber: Yup.string()
    .required("Phone Number is a required field")
    .matches(
      phoneNumberValidation.regex,
      phoneNumberValidation.message
    ),
  street: Yup.string().required("Street is a required field").max(85).matches(addressValidation.regex,addressValidation.message),
  apartment: Yup.string().max(85).matches(addressValidation.regex,addressValidation.message),
  // longest city in the world 85 letters length 
  // Taumatawhakatangihangakoauauotamateaturipukakapikimaungahoronukupokaiwhenuakitanatahu
  city: Yup.string().required("City is a required field").matches(cityValidation.regex,cityValidation.message).max(85),
  state: Yup.string().required("State is a required field"),
  zipCode: Yup.string().required("Zip Code is a required field").matches(zipCodeValidation.regex,zipCodeValidation.message),
});


function NewAddress({ setShow, address, edit = false }) {
  const { addAddress, updateAddress } = useContext(AddressContext);
  
  let initialValues = {
    country: "United States",
    fullName: "",
    phoneNumber: "",
    street: "",
    apartment: "",
    city: "",
    state: "",
    zipCode: "",
  };

  if (edit){ 
    initialValues = address;
  }

  async function formSubmitted(values) {
    let message = "Address Added Successfully";
    try {
      if (edit) {
        await updateAddress(values);
        message= "Address Updated Successfully";
      } else {
        await addAddress(values);
      }
      setShow && setShow(false);
      toast.success(message, {
        position: "bottom-right",
        draggable: true,
      });
    } catch (error) {
      toast.error("sorry some error occurred", {
        position: "bottom-right",
        draggable: true,
      });
    }
  }
  return (
    <div className="account__address-new-modal">
      <h1 className="account__address-new-title">Add A New Address</h1>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={addressSchema} onSubmit={formSubmitted}>
        {({ values, setFieldValue, isSubmitting, isValid, dirty, status }) => (
          <Form className="account__form" id="newForm">
            {/* Country */}
            <div>
              <Label title="Country" className="account__address-new-label"/>
              <Field name="country">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <div>
                    <CountryDropdown
                      className="account__address-new-dropdown"
                      value={field.value}
                      onChange={(value) => setFieldValue("country", value)}
                      name={field.name}
                      id="countryDropdown"
                    />
                  </div>
                )}
              </Field>
              <ErrorMessage name="country" id="countryError" component="div" className="error" />
            </div>

            {/* Full Name */}
            <div className="account__address-new-padding">
              <Label title="Full Name (First and last name)" className="account__address-new-label"/>
              <Field
                type="string"
                name="fullName"
                className="account__input"
                id="fullNameInput"
              />
              <ErrorMessage name="fullName" id="fullNameError" component="div" className="error" />
            </div>

            {/* Phone Number*/}
            <div className="account__address-new-padding">
              <Label title="Phone Number" className="account__address-new-label"/>
              <Field
                type="string"
                name="phoneNumber"
                className="account__input"
                id="phoneNumberInput"
              />
              <ErrorMessage name="phoneNumber" id="phoneNumberError" component="div" className="error" />
            </div>

            {/* Address*/}
            <div className="account__address-new-padding">
              <Label title="Address" className="account__address-new-label"/>
              <div className="pb-2">
                <Field
                  type="string"
                  name="street"
                  className="account__input"
                  placeholder="Street Address or P.O Box"
                  id="streetInput"
                />
                <ErrorMessage name="street" id="streetError" component="div" className="error" />
              </div>
              <div>
                <Field
                  type="string"
                  name="apartment"
                  className="account__input"
                  placeholder="Apt , suit , unit , building , floor , etc"
                  id="apartmentInput"
                />
                <ErrorMessage name="apartment" id="apartmentError" component="div" className="error" />
              </div>
            </div>
            <div className="flex gap-2">
              <div className="account__address-new-grid">
                {/* City */}
                <div className="account__address-new-padding">
                  <Label title="City" className="account__address-new-label"/>
                  <Field
                    type="string"
                    name="city"
                    className="account__input"
                    id="cityInput"
                  />
                  <ErrorMessage name="city" id="cityError" component="div" className="error" />
                </div>
              </div>
              <div className="account__address-new-grid">
                <div className="account__address-new-padding">
                  <Label title="state" className="account__address-new-label"/>
                  <Field name="state" id="stateDropdown">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta,
                    }) => (
                      <div>
                        <RegionDropdown
                          className="account__address-new-dropdown"
                          country={values.country}
                          value={field.value}
                          blankOptionLabel="Select"
                          defaultOptionLabel="Select"
                          onChange={(value) => setFieldValue("state", value)}
                          name={field.name}
                          
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage name="state" id="stateError" component="div" className="error" />
                </div>
              </div>
              <div className="account__address-new-grid">
                {/* ZipCode */}
                <div className="account__address-new-padding">
                  <Label title="Zip Code" className="account__address-new-label"/>
                  <Field
                    type="string"
                    name="zipCode"
                    id="zipCodeInput"
                    className="account__input"
                  />
                  <ErrorMessage name="zipCode" id="zipCodeError" component="div" className="error" />
                </div>
              </div>
            </div>
            {/* Submit Button */}
            <div className="account__address-new-padding">
              <button type="submit" className="account__btn-submit" id="addressBtn">
                {edit ? "Update Your Address":"Add Your Address"}
              </button>
            </div>
            {status && <div className="error" id="accountError">{status}</div>}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default NewAddress;
