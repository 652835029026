import React from "react";
import Star from "../../../shared/utilities/Star";

function SingleReview({review}) {
  return (
    <div className="review__item" data-id={review.id}>
      {/* User Profile */}
      <div className="review__item-header">
        <img src="/images/profile.png" id="profileImage" className="review__item-image" alt="profile pic" />
        <p>{review.username}</p>
      </div>

      {/* Rating */}
      <div className="review__item-headline-container">
        {/* <Star rating={4} /> */}
        <h1 className="review__item-headline">{review.headline}</h1>
      </div>
      {/* Date */}

      <p className="review__item-date">Reviewed on {new Date(review.createdAt).toLocaleDateString("en-us", { day: "numeric", year: "numeric", month: "short" })}</p>
      {/* Description */}
      <p className="review__item-comment">
        {review.comment}
      </p>
    </div>
  );
}

export default SingleReview;
