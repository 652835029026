import React from "react";
import { toast } from "react-toastify";
import Title from "./Title";

function ContextMenu() {
  function rightClick(event) {
    toast.success("You have right clicked", {
      position: "bottom-right",
      draggable: true,
    });
  }
  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Context Menu" />
      <p>
        A context menu is a menu in a graphical user interface that appears upon a right-click mouse operation. A
        context menu offers a limited set of choices.
      </p>
      <p>Right click on below box to get an Toast Message </p>

      <div>
        <div className="w-40 h-40 border-4 border-gray-800 border-dashed " onContextMenu={rightClick}></div>
      </div>
    </div>
  );
}

export default ContextMenu;
