import React, { useContext } from "react";
import { HiCheckCircle } from "react-icons/hi";
import { useParams } from "react-router-dom";

import OrderContext from "../../../contexts/customer/order/order-context";

function CancelConfirm() {
  const params = useParams();
  const { getOrderById } = useContext(OrderContext);
  const order = getOrderById(params.id);
  
  return (
    <div className="cancel__confirm-wrapper">
      {order && (
        <div className="cancel__confirm">
          <div>
            <div className="cancel__confirm-message">
              <HiCheckCircle /> <p>Your Order Has Been Cancelled</p>
            </div>
            <div>Payment refunded to cart ending with ...{order.cardNumber?.substr(-4)}</div>
          </div>
          <div className="cancel__confirm-summary">
            <p className="cancel__confirm-summary-title">Order Summary </p>
            <div className="cancel__confirm-summary-item">
              <p>Item total: </p>
              <p className="cancel__confirm-price">$ {order.price}</p>
            </div>
            <div className="cancel__confirm-summary-item">
              <p>Item quantity: </p>
              <p>{order.quantity}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CancelConfirm;
