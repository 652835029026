import React, { useContext, useState } from "react";
import { HiPlus } from "react-icons/hi";
import AddressContext from "../../../contexts/customer/address/address-context";
import Modal from "../../shared/utilities/Modal";
import NewAddress from "./NewAddress";
import Address from "./Address";

function Addresses() {
  const { addresses } = useContext(AddressContext);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="account__address-grid">
        <div
          className="account__address-new"
          onClick={() => {
            setShowModal(true);
          }}
        >
          <div className="account__address-new-wrapper">
            <HiPlus className="account__address-new-icon" />
            <p>Add Address</p>
          </div>
        </div>
        {addresses.map((item) => (
          <Address key={item.id} address={item}  />
        ))}
      </div>

      <Modal show={showModal} setShow={setShowModal} options={{ closeButton: true }} className="account__address-modal">
        <NewAddress setShow={setShowModal}  />
      </Modal>
    </>
  );
}

export default Addresses;
