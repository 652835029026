import React, { useState } from "react";
import TextArea from "../shared/utilities/TextArea";
import Title from "./Title";

function Wysiwag() {
  const [text, setText] = useState("");
  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="WYSIWIG Editor" id="title" />
      <form>
        <TextArea onChange={setText} value={text} id="wysiwagEditor"/>
      </form>
    </div>
  );
}

export default Wysiwag;
