import React, { useContext } from "react";
import Label from "../../../common/Label";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import OrderContext from "../../../../contexts/customer/order/order-context";
import { toast } from "react-toastify";

const schema = Yup.object().shape({
  headline: Yup.string().required(),
  comment: Yup.string().required(),
});

const initialValues = {
  headline: "",
  comment: "",
};



function NewReview({setShow , productId}) {
  const {addReview} = useContext(OrderContext);

 async function formSubmitted(values) {
    try {
      values.productId = productId;
      await addReview(values);
      setShow(false);
      toast.success("Your review was added successfully", {
        position: "bottom-right",
        draggable: true,
      });
    } catch (error) {
      
    }
  }

  return (
    <div className="new">
      <Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={formSubmitted}>
        {({ values, setFieldValue, isSubmitting, isValid, dirty, status }) => (
          <Form className="account__form" id="newReviewForm">
            {/* Full Name */}
            <div className="account__address-new-padding">
              <Label title="Add a Headline" className="account__address-new-label" />
              <Field type="string" placeholder="what is most important to know?" name="headline" className="account__input" id="headlineInput" />
              <ErrorMessage name="headline" id="headlineError" component="div" className="error" />
            </div>
            {/* Full Name */}
            <div className="account__address-new-padding">
              <Label title="Add a Written" className="account__address-new-label" />
              <Field as="textarea" placeholder="what did you like or dislike? what did you use this product for?" rows={4} name="comment" className="account__input" id="descriptionInput" />
              <ErrorMessage name="comment" id="commentError" component="div" className="error" />
            </div>

            {/* Submit Button */}
            <div className="account__address-new-padding">
              <button type="submit" className="account__btn-submit" id="reviewSubmitBtn">
                Add Your Review
              </button>
            </div>
            {status && (
              <div className="error" id="accountError">
                {status}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default NewReview;
