import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "./App.css";
import Home from "./components/customer/home/Home";
import Products from "./components/shared/product/Products";
import Product from "./components/shared/product/Product";
import Login from "./components/shared/auth/Login";
import Signup from "./components/shared/auth/Signup";
import Container from "./components/customer/Container";
import AuthState from "./contexts/shared/auth/AuthState";
import CartState from "./contexts/customer/cart/CartState";
import Cart from "./components/customer/cart/Cart";
import SidebarState from "./contexts/customer/sidebar/SidebarState";
import ProductState from "./contexts/customer/products/ProductState";
import HomeState from "./contexts/customer/home/HomeState";
import RecentlyViewedItemsState from "./contexts/customer/recently-viewed-items/RecentlyViewedItemsState";
import SignupSeller from "./components/shared/auth/SignupSeller";
import Dashboard from "./components/company/dashboard/Dashboard";
import CompanyContainer from "./components/company/Container";
import NewCompanyProduct from "./components/company/product/NewCompanyProduct";
import SharedContainer from "./components/shared/Container";
import CompanyProductState from "./contexts/company/products/CompanyProductState";
import AuthenticatedRoute from "./auth/AuthenticatedRoute";
import { Roles } from "./constants";
import AxiosInterceptorNavigate from "./auth/AxiosInterceptorNavigate";
import Profile from "./components/customer/profile/Profile";
import PaymentState from "./contexts/customer/payment/PaymentState";
import AddressState from "./contexts/customer/address/AddressState";
import CheckoutIndex from "./components/customer/checkout/Index";
import OrderIndex from "./components/customer/order/Index";
import ConfirmationPage from "./components/customer/checkout/ConfirmationPage";
import OrderState from "./contexts/customer/order/OrderState";
import CancelPage from "./components/customer/order/CancelPage";
import CancelConfirm from "./components/customer/order/CancelConfirm";
import ReturnConfirm from "./components/customer/order/ReturnConfirm";
import SeleniumIndex from "./components/selenium/Index";
import UploadFile from "./components/selenium/UploadFile";
import Radio from "./components/selenium/Radio";
import Alert from "./components/selenium/Alert";
import DragDrop from "./components/selenium/DragDrop";
import Dropdown from "./components/selenium/Dropdown";
import AddRemoveElements from "./components/selenium/AddRemoveElements";
import Checkbox from "./components/selenium/Checkbox";
import ContextMenu from "./components/selenium/ContextMenu";
import Hover from "./components/selenium/Hover";
import JavascriptAlerts from "./components/selenium/JavascriptAlerts";
import Wysiwag from "./components/selenium/Wysiwag";
import Inputs from "./components/selenium/Inputs";
import Links from "./components/selenium/Links";
import DynamicContent from "./components/selenium/DynamicContent";
import KeyPress from "./components/selenium/KeyPress";
import InfiniteScroll from "./components/selenium/InfiniteScroll";
import Pagination from "./components/selenium/Pagination";
import BrokenImages from "./components/selenium/BrokenImages";
import ChallengingDom from "./components/selenium/ChallengingDom";
import DynamicControls from "./components/selenium/DynamicControls";
import FloatingMenu from "./components/selenium/floating-menu/FloatingMenu";
import Iframe from "./components/selenium/Iframe";
import DynamicSelectIndex from "./components/selenium/dynamic-select/Index";
function App() {
  return (
    <AuthState>
      <ProductState>
        <SidebarState>
          <PaymentState>
            <AddressState>
              <OrderState>
                <CartState>
                  <HomeState>
                    <RecentlyViewedItemsState>
                      <SharedContainer>
                        <CompanyProductState>
                          <Router>
                            <AxiosInterceptorNavigate />
                            <Routes>
                              <Route
                                path="/company/"
                                element={<AuthenticatedRoute Component={CompanyContainer} roles={Roles.Company} />}
                              >
                                <Route path="dashboard" element={<Dashboard />} />
                                <Route path="product/new" element={<NewCompanyProduct />} />
                                <Route path="product/edit/:id" element={<NewCompanyProduct edit={true} />} />
                              </Route>

                              <Route path="/auth/">
                                <Route path="login" element={<Login />} />
                                <Route path="sign-up/seller" element={<SignupSeller />} />
                                <Route path="sign-up" element={<Signup />} />
                              </Route>
                              {/* Customer  */}
                              <Route path="/" element={<Container />}>
                                <Route path="" element={<Home />} />
                                {/* Route for buy again */}
                                <Route
                                  path="checkout/order/:id"
                                  element={<AuthenticatedRoute Component={CheckoutIndex} roles={Roles.Customer} />}
                                />
                                <Route
                                  path="checkout"
                                  element={<AuthenticatedRoute Component={CheckoutIndex} roles={Roles.Customer} />}
                                />
                                <Route
                                  path="order"
                                  element={<AuthenticatedRoute Component={OrderIndex} roles={Roles.Customer} />}
                                />
                                <Route
                                  path="order/:id/cancel/confirm"
                                  element={<AuthenticatedRoute Component={CancelConfirm} roles={Roles.Customer} />}
                                />
                                <Route
                                  path="order/:id/return/confirm"
                                  element={<AuthenticatedRoute Component={ReturnConfirm} roles={Roles.Customer} />}
                                />
                                <Route
                                  path="order/:id"
                                  element={<AuthenticatedRoute Component={CancelPage} roles={Roles.Customer} />}
                                />
                                <Route
                                  path="order/:id/confirm"
                                  element={<AuthenticatedRoute Component={ConfirmationPage} roles={Roles.Customer} />}
                                />
                                <Route
                                  path="profile"
                                  element={<AuthenticatedRoute Component={Profile} roles={Roles.Customer} />}
                                />
                                <Route path="products" element={<Products />} />
                                <Route path="products/:id" element={<Product />} />
                                <Route
                                  path="shopping-cart"
                                  element={<AuthenticatedRoute Component={Cart} roles={Roles.Customer} />}
                                />
                              </Route>
                              {/* Selenium */}
                              <Route path="/selenium/" element={<SeleniumIndex />}>
                                <Route path="alert" element={<Alert />} />
                                <Route path="add-remove-elements" element={<AddRemoveElements />} />
                                <Route path="broken-images" element={<BrokenImages />} />
                                <Route path="challenging-dom" element={<ChallengingDom />} />
                                <Route path="checkbox" element={<Checkbox />} />
                                <Route path="context-menu" element={<ContextMenu />} />
                                <Route path="drag-drop" element={<DragDrop />} />
                                <Route path="dropdown" element={<Dropdown />} />
                                <Route path="dynamic-content" element={<DynamicContent />} />
                                <Route path="dynamic-controls" element={<DynamicControls />} />
                                <Route path="dynamic-select" element={<DynamicSelectIndex />} />
                                <Route path="floating-menu" element={<FloatingMenu />} />
                                <Route path="hover" element={<Hover />} />
                                <Route path="iframe" element={<Iframe />} />
                                <Route path="infinite-scroll" element={<InfiniteScroll />} />
                                <Route path="inputs" element={<Inputs />} />
                                <Route path="javascript-alerts" element={<JavascriptAlerts />} />
                                <Route path="key-press" element={<KeyPress />} />
                                <Route path="links" element={<Links />} />
                                <Route path="pagination" element={<Pagination />} />
                                <Route path="radio" element={<Radio />} />
                                <Route path="upload" element={<UploadFile />} />
                                <Route path="wysiwig" element={<Wysiwag />} />
                              </Route>
                            </Routes>
                          </Router>
                          <ToastContainer />
                        </CompanyProductState>
                      </SharedContainer>
                    </RecentlyViewedItemsState>
                  </HomeState>
                </CartState>
              </OrderState>
            </AddressState>
          </PaymentState>
        </SidebarState>
      </ProductState>
    </AuthState>
  );
}

export default App;
