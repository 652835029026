import React  from 'react'
import {GoPrimitiveDot} from 'react-icons/go'
import { useParams } from 'react-router-dom';
import AddressIndex from './address/Index';
import PaymentIndex from './payment/Index';
import PlaceOrder from './PlaceOrder';
function Index() {
  const params = useParams();
  return (
    <div className='checkout'>
        <div className='checkout__layout'>
            <div className='checkout__layout-information'>
                <h1 className='checkout__title'><GoPrimitiveDot /> Choose a shipping Address</h1>
                <AddressIndex />
                <br /><br />
                <h1 className='checkout__title'><GoPrimitiveDot /> Choose a Payment Method</h1>
                <PaymentIndex />
            </div>
            <div className='checkout__layout-order'>
              <PlaceOrder id={params.id} isBuyAgain={!!params.id} />
            </div>
        </div>
    </div>
  )
}

export default Index