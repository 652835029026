import React, { useContext } from "react";
import { HiCheckCircle } from "react-icons/hi";
import { useParams } from "react-router-dom";
import OrderContext from "../../../contexts/customer/order/order-context";

function ConfirmationPage() {
  
  const params = useParams();
  const { getOrderById } = useContext(OrderContext);
  const order = getOrderById(params.id);


  return (
    <div className="w-full p-4 flex justify-center">
      {order && (
        <div className="shadow md:w-3/4 bg-white p-4">
          <div className="text-xl  font-semibold flex items-center gap-2 text-teal-700">
            <HiCheckCircle /> <p>Order Placed, Thanks</p>
          </div>
          <p>Your confirmation number is {order.number}.</p>

          <div className="">
            <span className="font-bold">Shipping To {order.fullName}</span>
            <span> {order.street} ,</span>
            <span> {order.apartment} {order.apartment && ","}</span>
            <span> {order.city.toUpperCase()} ,</span>
            <span> {order.state.toUpperCase()} ,</span>
            <span> {order.zipCode},</span>
            <span> {order.country}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConfirmationPage;
