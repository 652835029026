import React from "react";
import Title from "../Title";

import DynamicSelect from "./DynamicSelect";
const numPassengers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
function Index() {
  return (
    <div className="flex flex-col px-4 gap-4">
      <Title title="Dynamic Select" />
      <div className="flex gap-4">
        <div className="w-1/3">
          <DynamicSelect label="From" placeholder="Country or City" />
        </div>
        <div className="w-1/3">
          <DynamicSelect label="To" placeholder="Country or City" />
        </div>
        <div className="w-1/3">
          <p>Number of Passengers</p>
          <select name="country" className="bg-gray-100 py-1.5 px-2 w-full">
            {numPassengers.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex gap-4 ">
        <div className="w-1/3">
          <p>Depart</p>
          <input
            type="date"
            name="date"
            id="dateInput"
            className="w-full border border-gray-400 py-1 px-2 focus:outline-blue-600"
          />
        </div>
        <div className="w-1/3">
          <p>Return</p>
          <input
            type="date"
            name="date"
            id="dateInput"
            className="w-full border border-gray-400 py-1 px-2 focus:outline-blue-600"
          />
        </div>

        <div className="w-1/3">
          <button className="bg-blue-800 mt-6 text-blue-100 py-1.5 px-2 w-full rounded  hover:bg-blue-700">
            Search
          </button>
        </div>
      </div>
    </div>
  );
}

export default Index;
