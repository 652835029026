import React, { useContext } from "react";
import PaymentContext from "../../../contexts/customer/payment/payment-context";
import NewPayment from "./NewPayment";
import Payment from "./Payment";
import Payments from "./Payments";

function Index() {
  const {selectedMethodIndex} = useContext(PaymentContext);
  return (
    <div>
      <h1 className="account__title">Wallet</h1>
      <div className="account__payment">
        <div className="account__payments">
          <h1 className="account__payments-title">Cards & Accounts</h1>
          <div className="account__payments-wrapper">
            <Payments />
            
          </div>
        </div>
        <div className="account__payment-detail">
          {selectedMethodIndex === -1 ? <NewPayment /> : <Payment paymentIndex={selectedMethodIndex} /> }
        </div>
      </div>
    </div>
  );
}

export default Index;
