import React, { useState } from "react";
import Label from "../common/Label";
import Title from "./Title";

function Inputs() {
  const [range,setRange] = useState(0)

  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Inputs" id="title" />

      <form>
        <div className="md:w-1/2">
          <Label title="Text" className="block py-2" />
          <input
            type="text"
            placeholder="Enter your name"
            name="text"
            id="textInput"
            className="w-full border border-gray-400 input-shadow py-1 px-2 focus:outline-none;"
          />
        </div>
        <div className="md:w-1/2">
          <Label title="Email" className="block py-2" />
          <input
            type="email"
            placeholder="Enter your Email"
            name="email"
            id="emailInput"
            className="w-full border border-gray-400 input-shadow py-1 px-2 focus:outline-none;"
          />
        </div>
        <div className="md:w-1/2">
          <Label title="Password" className="block py-2" />
          <input
            type="password"
            placeholder="Enter your password"
            name="password"
            id="passwordInput"
            className="w-full border border-gray-400 input-shadow py-1 px-2 focus:outline-none;"
          />
        </div>
        <div className="md:w-1/2">
          <Label title="Date" className="block py-2" />
          <input
            type="date"
            name="date"
            id="dateInput"
            className="w-full border border-gray-400 input-shadow py-1 px-2 focus:outline-none;"
          />
        </div>
        <div className="md:w-1/2">
          <Label title="Number" className="block py-2" />
          <input
            type="number"
            name="number"
            placeholder="Enter your age"
            title="I am a doctor,Cliff, not a mathematician"
            id="numberInput"
            className="w-full border border-gray-400 input-shadow py-1 px-2 focus:outline-none;"
          />
        </div>
        <div className="md:w-1/2">
          <Label title="Range" className="block py-2" />
          <input
            type="range"
            name="range"
            id="rangeInput"
            className="w-full"
            min={0}
            max={50}
            value={range}
            onChange={(e)=>setRange(e.target.value)}
          />
          {range}
        </div>
        <div>
          <Label title="Color" className="block py-2" />
          <input
            type="color"
            name="color"
            placeholder="Enter your age"
            id="colorInput"
            className="border border-gray-400 input-shadow py-1 px-2 focus:outline-none;"
          />
        </div>
      </form>
    </div>
  );
}

export default Inputs;
