import React from "react";
const BackDrop = ({ click }) => {
  return (
    <div
      className="backdrop"
      onClick={click}
    ></div>
  );
};

export default BackDrop;
