import { Outlet } from "react-router-dom";
import { useContext, useEffect } from "react";

import TopNav from "../shared/nav/TopNav";
import SecondNav from "../shared/nav/SecondNav";
import Footer from "../shared/nav/Footer";
import SidebarContext from "../../contexts/customer/sidebar/sidebar-context";
import AuthContext from "../../contexts/shared/auth/auth-context";
import ProductContext from "../../contexts/customer/products/product-context";
import RecentlyViewedItemsContext from "../../contexts/customer/recently-viewed-items/recently-viewed-items-context";
import PaymentContext from "../../contexts/customer/payment/payment-context";
import AddressContext from "../../contexts/customer/address/address-context";
import OrderContext from "../../contexts/customer/order/order-context";
import HomeContext from "../../contexts/customer/home/home-context";

export default function Container() {
  const { fetchCategories } = useContext(SidebarContext);
  const { fetchProductNames , fetchProducts } = useContext(ProductContext);
  const { fetchRecentlyViewedItems } = useContext(RecentlyViewedItemsContext);
  const { fetchPaymentMethods } = useContext(PaymentContext);
  const { fetchAddresses } = useContext(AddressContext);
  const { fetchOrders } = useContext(OrderContext);
  const { user } = useContext(AuthContext);
  const { fetchHomeData } = useContext(HomeContext);

  useEffect(() => {
    fetchCategories();
    fetchProductNames();
    fetchRecentlyViewedItems();
    fetchHomeData();
    fetchProducts();
  }, []);

  useEffect(() => {
    if (user) {
      fetchPaymentMethods();
      fetchAddresses();
      fetchOrders();
    }
  }, [user]);
  return (
    <div className="main-container">
      <div>
        <TopNav />
        <SecondNav />
        <div className="bg-gray-100">
          <Outlet />
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
