import React, { useContext } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import Label from "../../common/Label";
import OrderContext from "../../../contexts/customer/order/order-context";
import { toast } from "react-toastify";

let initialValues = {
  reason: "",
  dropOff: "",
};

function CancelPage() {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const { getOrderById, cancelOrder, toggleCheck, returnOrder } = useContext(OrderContext);
  const order = getOrderById(params.id);

  // is this a return page or a cancel page
  const isReturnPage = searchParams.get("type") === "return";
  let schema;

  if (isReturnPage) {
    schema = Yup.object().shape({
      reason: Yup.string().required(),
      dropOff: Yup.string().required(),
    });
  } else {
    schema = Yup.object().shape({
      reason: Yup.string().required(),
    });
  }
  const navigate = useNavigate();

  function isItemSelected() {
    return order.products.some((item) => item.checked);
  }

  async function returnFormSubmitted(values) {
    const result = await returnOrder(order ,values);

    if (!isItemSelected()) {
      return toast.warn("Please select at least on item", {
        position: "bottom-right",
        draggable: true,
      });
    }

    navigate(`/order/${order.id}/return/confirm`, { state: { number: result.number } });
  }

  async function formSubmitted(values) {
    try {
      if (isReturnPage) {
        returnFormSubmitted(values);
      } else {
        await cancelOrder(order.id);
        navigate(`/order/${order.id}/cancel/confirm`);
      }
    } catch (error) {}
  }

  return (
    <div className="order__cancel-wrapper">
      {order && (
        <div className="order__cancel">
          <h1 className="order__cancel-affirmation">
            Are you sure you want to {isReturnPage ? "return" : "cancel"} this order?
          </h1>
          <div className="order__cancel-content">
            <div className="order__cancel-count">
              <h1>Items Ordered</h1>
              <p>Total Price ${order.price}</p>
            </div>
            <div className="order__cancel-items">
              {order.products.map((item, index) => (
                <div className="order__cancel-item" key={item.id} data-id={item.id}>
                  <p key={item.id} className="order__cancel-item-wrapper">
                    <span>{item.quantity} of </span>
                    <span className="order__cancel-item-name" onClick={() => navigate(`/products/${item.id}`)}>
                      {item.descriptiveName}
                    </span>
                  </p>
                  <p className="order__cancel-item-price">
                    $ {item.price * item.quantity}{" "}
                    {isReturnPage && (
                      <input type="checkbox" checked={item.checked} onChange={() => toggleCheck(order, item)} />
                    )}
                  </p>
                </div>
              ))}
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={schema}
                validateOnBlur={false}
                onSubmit={formSubmitted}
              >
                {({ values, setFieldValue, isSubmitting, isValid, dirty, status }) => (
                  <Form id="cancelForm">
                    <div className="order__cancel-form">
                      <div className="order__cancel-form-container">
                        <div>
                          <div className="order__cancel-input-wrapper">
                            <Label title="Why are you returning this?" className="order__cancel-label" />
                            <Field className="order__cancel-input" as="select" name="reason" id="reasonInput">
                              <option value="">Select {isReturnPage ? "Return" : "Cancelation"} Reason</option>
                              <option value="wrong">Bought wrong item</option>
                              <option value="damage">Item damaged</option>
                            </Field>
                          </div>

                          <ErrorMessage name="reason" id="reasonError" component="div" className="error" />
                        </div>
                        {isReturnPage && (
                          <div>
                            <div>
                              <div className="order__cancel-input-wrapper">
                                <Label title="Where do you want to drop this off?" className="font-bold w-1/2" />
                                <Field className="order__cancel-input" as="select" name="dropOff" id="dropOffInput">
                                  <option value="">Select Where to Drop Off</option>
                                  <option value="usps">USPS</option>
                                  <option value="fedex">FedEx</option>
                                  <option value="ups">UPS</option>
                                </Field>
                              </div>
                              <ErrorMessage name="dropOff" id="dropOffError" component="div" className="error" />
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="order__cancel-btn-wrapper">
                          <button className="order__cancel-submit" id="orderSubmitBtn" type="submit">
                            {isReturnPage ? "Return" : "Cancel"} Order
                          </button>
                        </div>
                        <div className="order__cancel-btn-wrapper">
                          <button id="backBtn" type="button" className="order__cancel-back" onClick={() => navigate("/order")}>
                            Return to order summmary
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CancelPage;
