import React from "react";
import Title from "./Title";

function Hover() {
  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Hovers" />

      <p className="py-2">move your mouse over images to see additional information</p>
      <div className="flex gap-4">
        <div className="md:w-48">
          <img src="/images/profile.png" className="hover__image" alt="profile" id="image1" />
          <div className="hover__information" id="firstPerson">
            <h1 className="font-bold">Jane Doe</h1>
            <p>Senior Frontend Developer</p>
          </div>
        </div>
        <div className="md:w-48">
          <img src="/images/profile.png" className="hover__image" alt="profile" id="image2"/>
          <div className="hover__information" id="secondPerson" >
            <h1 className="font-bold">John Doe</h1>
            <p>Junior Java Developer</p>
          </div>
        </div>
        <div className="md:w-48">
          <img src="/images/profile.png" className="hover__image"  alt="profile" id="image3" />
          <div className="hover__information" id="thirdPerson">
            <h1 className="font-bold">Robert Downey Jr.</h1>
            <p>You know who he is</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hover;
