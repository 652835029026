import Axios from "axios";

const api = Axios.create({ baseURL: process.env.REACT_APP_API_URL });

api.interceptors.request.use(function (config) {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.accessToken)
    config.headers["x-access-token"] = user.accessToken;

  return config;
});

// Reference: https://stackoverflow.com/questions/71548673/react-router-v6-how-to-use-navigate-axios-interceptors
export const AxiosInterceptorsSetup = ({ navigate , toast }) => {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        toast.error("Please Login, You are not Authenticated",{
          position: 'bottom-right',
          draggable: true,
        });
        navigate("/auth/login");
      }
      else if(error.response.status===403){
        toast.error("You are not Authorized to do this action",{
          position: 'bottom-right',
          draggable: true,
        });
      }
      else if(error.response.status===500){
        toast.error("Sorry Some Server Error Occurred",{
          position: 'bottom-right',
          draggable: true,
        });
      }
      return Promise.reject(error);
    }
  );
};

export default api;
