import React, { useContext, useEffect, useReducer } from "react";

import AuthContext from "../../../contexts/shared/auth/auth-context";
import {
  ADD_ITEM,
  CHECK_ALL,
  DELETE_ITEM,
  EDIT_ITEM,
  RESET,
  SET_ITEMS,
  TOGGLE_CHECK,
  UNCHECK_ALL,
  PLACE_ORDER,
} from "./cart-actions";
import { Roles } from "../../../constants";
import CartContext from "./cart-context";
import CartReducer from "./cart-reducer";
import api from "../../api";
import OrderContext from "../order/order-context";

function CartState({ children }) {
  const { user } = useContext(AuthContext);
  const { addOrderItem } = useContext(OrderContext);

  useEffect(() => {
    setState();
  }, [user]);

  const initialState = {
    items: [],
    totalQuantity: 0,
    totalPrice: 0,
    totalSelectedQuantity: 0,
    totalSelectedPrice: 0,
  };

  const [state, dispatch] = useReducer(CartReducer, initialState);

  // Called to load data from database
  const setState = async () => {
    if (!user || user.role !== Roles.Customer) {
      dispatch({
        type: RESET,
      });

      return;
    }

    try {
      const result = await api.get(`/shopping-cart?buyerId=${user.roleId}`);
      dispatch({
        type: SET_ITEMS,
        payload: result.data,
      });
    } catch (error) {}
  };

  const addCartItem = async (item) => {
    const index = cartItemIndex(item.productId);
    if (index >= 0) {
      const newItem = state.items[index];
      newItem.newQuantity = +item.quantity + newItem.quantity;

      editCartItem(newItem);
      return;
    }
    try {
      const result = await api.post("/shopping-cart", {
        productId: +item.productId,
        quantity: +item.quantity,
      });
      result.data.quantity = +result.data.quantity;
      result.data.productName = item.productName;
      result.data.productImageUrl = item.productImageUrl;
      // only checked items will be proceed to checkout
      result.data.checked = true;
      dispatch({
        type: ADD_ITEM,
        payload: result.data,
      });
    } catch (error) {
      console.log(error);
      // #to-do create exception response
    }
  };

  const deleteCartItem = async (item) => {
    if (!user) {
      return;
    }
    const result = await api.delete(`/shopping-cart/${item.id}`);
    dispatch({
      type: DELETE_ITEM,
      payload: result.data,
    });
  };

  const toggleCheck = (item) => {
    dispatch({
      type: TOGGLE_CHECK,
      payload: item,
    });
  };
  const toggleCheckAll = () => {
    if (state.totalSelectedQuantity !== state.totalQuantity) {
      dispatch({
        type: CHECK_ALL,
      });
    } else {
      dispatch({
        type: UNCHECK_ALL,
      });
    }
  };
  const editCartItem = async (item) => {
    const result = await api.patch(`/shopping-cart/${item.id}`, {
      quantity: item.newQuantity,
    });
    if (result) {
      dispatch({
        type: EDIT_ITEM,
        payload: item,
      });
    }
  };

  const placeOrder = async () => {
    const checkedItems = state.items
      .filter((item) => item.checked)
      .map((item) => ({ productId: item.productId, quantity: item.quantity }));

    const order = {
      products: checkedItems,
    };

    try {
      const result = await addOrderItem(order);

      await api.delete(`/shopping-cart/bulk-delete`, {
        data: { items: state.items.filter((item) => item.checked).map((item) => item.id) },
      });

      dispatch({
        type: PLACE_ORDER,
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  // Utilities

  const cartItemIndex = (productId) => {
    return state.items.findIndex((elem) => elem.productId === productId);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems: state.items,
        totalQuantity: state.totalQuantity,
        totalPrice: state.totalPrice,
        totalSelectedQuantity: state.totalSelectedQuantity,
        totalSelectedPrice: state.totalSelectedPrice,
        toggleCheck,
        toggleCheckAll,
        addCartItem,
        deleteCartItem,
        editCartItem,
        cartItemIndex,
        placeOrder,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export default CartState;
