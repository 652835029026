import React from "react";

function SearchItem({ item, searchTerm }) {
  const searchTermIndex = item.toUpperCase().indexOf(searchTerm.toUpperCase());

  const first = item.substring(0, searchTermIndex);
  const second = item.substring(searchTermIndex, searchTermIndex + searchTerm.length);
  const third = item.substring(searchTermIndex + searchTerm.length, item.length);

  return (
    <div className="flex">
      {searchTermIndex >= 0 ? (
        <>
          <pre>{first}</pre>
          <pre className="font-bold">{second}</pre>
          <pre>{third}</pre>
        </>
      ) : (
        <span>{item}</span>
      )}
    </div>
  );
}

export default SearchItem;
