import React from "react";
import ReactDOM from "react-dom";
import {MdClose} from 'react-icons/md';

import BackDrop from "./BackDrop";
const Modal = ({
  show,
  setShow,
  options: { backdrop = true, closeButton = false , closeClassName=''} = {},
  children,
  className,
}) => {
  const ROOT = document.getElementById("root");

  return (
    <div>
      {show &&
        ReactDOM.createPortal(
          <div>
            {backdrop && <BackDrop click={() => setShow(false)} />}
            <div
              className="modal"
              onClick={() => setShow(false)}
            >
              <div
                onClick={(e) => e.stopPropagation()}
                className={`modal__content ${className} `}
              >
                {closeButton && (
                  <button
                    onClick={() => setShow(false)}
                    className={`${closeClassName} modal__close-btn`}
                    id="closeBtn"
                  >
                    <MdClose className="hover:text-red-600" />
                  </button>
                )}
                {children}
              </div>
            </div>
          </div>,
          ROOT
        )}
    </div>
  );
};

export default Modal;
