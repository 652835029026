import React, { useContext } from "react";
import PaymentContext from "../../../contexts/customer/payment/payment-context";
import PaymentImage from "./PaymentImage";
import PaymentTitle from "./PaymentTitle";

function Payments() {
  const { selectedMethodIndex, setSelectedMethodIndex, paymentMethods } = useContext(PaymentContext);
  
  return (
    <>
      {paymentMethods.map((item, index) => (
        <div
        onClick={()=>setSelectedMethodIndex(index)}
          key={item.id}
          data-id={item.id}
          className={`${
            selectedMethodIndex === index && "account__payment-selected"
          } account__payment-item`}
        >
          <div className="account__payment-image-wrapper">
            <PaymentImage cardNumber={item.cardNumber} />
          </div>
          <div className="account__payment-sub">
            <PaymentTitle className="account__payment-sub-title" cardNumber={item.cardNumber} />
            <p className="account__payment__sub-text">Debit Card Ending in .... {item.cardNumber.substr(-4)}</p>
          </div>
        </div>
      ))}
      <div
      onClick={()=>setSelectedMethodIndex(-1)}
        className={`${
          selectedMethodIndex === -1 && "border-orange-400 bg-white"
        } cursor-pointer shadow border-l-4 rounded px-2 py-4 flex items-center gap-2`}
      >
        <div className="w-4/12">
          <img src="/images/add.png" className="w-full" alt="add payment method" />
        </div>
        <div className="w-8/12">
          <p className="text-sm text-blue-700 hover:underline hover:text-red-700">Add a payment method</p>
        </div>
      </div>
    </>
  );
}

export default Payments;
