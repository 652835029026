import React from "react";

import PaymentIndex from "../payment/Index";
import AddressIndex from "../address/Index";
import PersonalInformation from "../profile/PersonalInformation";
function Profile() {
  return (
    <div className="account">
      <div className="account__information-wrapper">
        <PersonalInformation />
      </div>
      <PaymentIndex />
      <AddressIndex />
    </div>
  );
}

export default Profile;
