import React, { useEffect, useState } from "react";
import api from "../../contexts/api";
import Title from "./Title";

function DynamicContent() {
  const [quote, setQuote] = useState({
    content: "",
    author: "",
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getQuote();
  }, []);
  async function getQuote() {
    try {
      if (loading) return;
      setLoading(true);
        //Ref: https://github.com/lukePeavey/quotable
      const quote = (await api.get("https://api.quotable.io/random")).data;

      setQuote({
        content: quote.content,
        author: quote.author,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Dynamic Content" id="title" />
      <div className="flex justify-center">
        <button id="newQuoteBtn" onClick={getQuote} className="bg-blue-800 text-blue-100 py-2 px-3 rounded m-2 hover:bg-blue-700">
          Get Random Quote
        </button>
      </div>
      <div className="max-w-4xl p-4 text-gray-800 bg-gray-50 rounded-lg shadow">
        <div className="mb-2">
          <div className="h-3 text-3xl text-left text-gray-600">“</div>
          <p className="px-4 text-sm text-center text-gray-600" id="quote">{quote.content}</p>
          <div className="h-3 text-3xl text-right text-gray-600">”</div>
        </div>
        <p className="font-semibold text-center" id="author">{quote.author}</p>
      </div>
    </div>
  );
}

export default DynamicContent;
