import React, { useContext, useEffect, useRef, useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";

import ProductContext from "../../../../contexts/customer/products/product-context";
import SearchItem from './SearchItem';
function SearchInput({ className, items, search , selectedCategoryId}) {
  const DROPDOWN_ITEMS_SIZE = 10;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const {productNames} = useContext(ProductContext);
  const [showItems, setShowItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const dropdownRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  function itemSelected(item) {
    search(item);
    clearInputField();
  }

  function clearInputField() {
    setDropdownVisible(false);
    setSearchTerm("");
    setSelectedItemIndex(-1);
    setShowItems([]);
  }

  function handleClickOutside(e) {
    if (!dropdownRef?.current?.contains(e.target)) {
      setDropdownVisible(false);
    }
  }
  function inputChanged(e) {
    const term = e.target.value;
    setSearchTerm(term);

    if (term === "") {
      clearInputField();
      return;
    }
    setDropdownVisible(true);
    let i = 0;
    // #to-do make following line more efficient
    const newItems = productNames.filter((item) => {
      
      return (
       (+selectedCategoryId===-1 || +selectedCategoryId === +item.parentCategoryId) && item.name.toUpperCase().includes(term.toUpperCase()) &&
        i++ < DROPDOWN_ITEMS_SIZE
      );
    }).map(item=>item.name);

    setShowItems(newItems);
  }
  function searchButtonClicked(e) {
    if (searchTerm !== "") {
      search(searchTerm);
      clearInputField();
    }
  }
  function keyCheck(e) {
    let itemIndex;
    switch (e.keyCode) {
      case 13:
        searchButtonClicked();
        break;
      case 38:        
        // up arrow key condition
        itemIndex =
          selectedItemIndex > 0 ? selectedItemIndex - 1 : showItems.length - 1;
        setSearchTerm(showItems[itemIndex]);
        setSelectedItemIndex(itemIndex);
        break;
      case 40:
        // down arrow key condition
        itemIndex = (selectedItemIndex + 1) % showItems.length;
        setSearchTerm(showItems[itemIndex]);
        setSelectedItemIndex(itemIndex);
        break;
        default: 
    }
  }
  return (
    <div className={`relative ${className}`}>
      {/* Search Section */}
      <div className="flex rounded">
        <input
          type="text"
          className="search__input"
          value={searchTerm}
          onChange={inputChanged}
          onKeyUp={keyCheck}
          id="searchInput"
        />
        <button
          className="search__btn"
          id="searchBtn"
          onClick={searchButtonClicked}
        >
          <HiOutlineSearch />
        </button>
      </div>
      {/* Dropdown Section */}
      {dropdownVisible && (
        <div
          className="search__suggestion"
          ref={dropdownRef}
        >
          {showItems.map((item, index) => (
            <div
              key={item}
              className={`search__suggestion-item ${
                selectedItemIndex === index ? "search__suggestion-active" : ""
              } `}
              onClick={() => itemSelected(item)}
            >
              <SearchItem item={item} searchTerm={searchTerm} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchInput;
