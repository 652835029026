import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {toast} from 'react-toastify';

import ObjectInput from "../../shared/utilities/ObjectInput";
import TextArea from "../../shared/utilities/TextArea";
import AuthContext from "../../../contexts/shared/auth/auth-context";
import SidebarContext from "../../../contexts/customer/sidebar/sidebar-context";
import CompanyProductContext from "../../../contexts/company/products/company-product-context";
import Image from "../../shared/utilities/Image";
import api from "../../../contexts/api";

function NewCompanyProduct({ edit = false }) {
  // Form Fields

  // only for edit mode
  const [id, setId] = useState(null);
  const [image, setImage] = useState(null);

  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [description, setDescription] = useState("");
  const [details, setDetails] = useState();
  const [price, setPrice] = useState(0);
  const [legalDisclaimer, setLegalDisclaimer] = useState("");
  const [categoryId, setCategoryId] = useState(11);
  const [file, setFile] = useState(null);

  // contexts
  const { user } = useContext(AuthContext);
  const { categories, fetchCategories } = useContext(SidebarContext);
  const { addProduct , updateProduct } = useContext(CompanyProductContext);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    fetchCategories();

    async function fetchProduct() {
      const product = (
        await api.get(`/product/${params.id}`)
      ).data;
      setName(product.name);
      setDescription(product.description);
      setAbout(product.about);
      setPrice(+product.price);
      setLegalDisclaimer(product.legalDisclaimer);
      setCategoryId(product.categoryId);
      setId(product.id);
      setImage(product.imageUrl);
      setDetails(product.details);
      // convertDetailsStringToArray(product.details)
    }
    if (edit) {
      fetchProduct();
    }
  }, []);

  const saveFile = (e) => {
    const reader = new FileReader();
    const files = e.target.files;
    const file = files[0];

    setFile(file);
    
    // Get the file object after upload and read the
    // data as URL binary string
    reader.readAsDataURL(file);

    // Once loaded, do something with the string
    reader.addEventListener("load", (event) => {     
      setImage(event.target.result);
    });
  };

  async function submitForm(e) {
    e.preventDefault();
    // transform details into a json object
    if (!user || !user.role === "company") return;

    const objectDetails = {};
    details.forEach((element) => {
      if (element.title && element.value)
        objectDetails[element.title] = element.value;
    });
    const item = {
      name,
      description,
      about,
      details: objectDetails,
      price,
      legalDisclaimer,
      sellerId: user.roleId,
      categoryId: +categoryId,
    };

    const formData = new FormData();
    file && formData.append("file", file);

    formData.append("name", item.name);
    formData.append("description", item.description);
    formData.append("about", item.about);
    formData.append("details", JSON.stringify(objectDetails));
    formData.append("price", +item.price);
    formData.append("legalDisclaimer", item.legalDisclaimer);
    formData.append("sellerId", +item.sellerId);
    formData.append("categoryId", +item.categoryId);

    try {
      if(edit){
        await updateProduct(formData,id);
      }else{
        await addProduct(formData);
      }      
      
      clearForm();
      navigate("/company/dashboard");
    } catch (error) {
      console.log(error);
    }
  }

  function clearForm() {
    setName("");
    setDescription("");
    setAbout("");
    setDetails("");
    setPrice(0);
    setLegalDisclaimer("");
    setCategoryId(13);
  }

  return (
    <form className="py-8 px-72 bg-gray-100" onSubmit={submitForm}>
      <h1 className="text-xl font-bold">New Product</h1>

      {/* Name */}
      <div>
        <h2 className="text-sm font-bold py-1">
          Name <span className="text-red-600">*</span>
        </h2>
        <input
          type="text"
          className="border bg-white py-1 px-2 focus:outline-none w-full"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      {/* Description */}
      <div>
        <h2 className="text-sm font-bold py-1">
          Description <span className="text-red-600">*</span>
        </h2>
        <textarea
          className="border bg-white py-1 px-2 focus:outline-none w-full"
          required
          rows={3}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      {/* Categories */}
      <div>
        <h2 className="text-sm font-bold py-1">
          Category <span className="text-red-600">*</span>
        </h2>

        <select
          className="py-2 w-1/2"
          value={categoryId}
          onChange={(e) => setCategoryId(e.target.value)}
        >
          {Object.keys(categories).map((topLevel) => (
            <optgroup
              key={categories[topLevel].id}
              label={categories[topLevel].title}
            >
              {categories[topLevel].children.map((subLevel) => (
                <option className="py-2" value={subLevel.id} key={subLevel.id}>
                  {subLevel.title}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
      </div>

      {/* About Item */}
      <div>
        <h2 className="text-sm font-bold py-1">About This item</h2>
        <TextArea onChange={setAbout} value={about} edit={ about!==""}  />
      </div>

      {/* Price and Image */}
      <div className="flex gap-2">
        <div className="w-1/2">
          <h2 className="text-sm font-bold py-1">
            Price <span className="text-red-600">*</span>
          </h2>
          <input
            type={"number"}
            value={price}
            min={0}
            onChange={(e) => setPrice(e.target.value)}
            required
            className="border bg-white py-1 px-2 focus:outline-none w-full"
          />
          <p className="text-xs py-1 text-gray-800">All Prices in $</p>
        </div>
        <div className="w-1/2">
          <h2 className="text-sm font-bold py-1">
            Image <span className="text-red-600">*</span>
          </h2>
          <input type={"file"} onChange={saveFile} required={edit ? false : true} />
        </div>
      </div>
      <div className="flex gap-2">
        <div className="w-1/2"></div>
        <div className="w-1/4">{image && <Image src={image} />}</div>
      </div>

      {/* Disclaimer */}
      <div>
        <h2 className="text-sm font-bold py-1">Legal Disclaimer</h2>
        <textarea
          className="border bg-white py-1 px-2 focus:outline-none w-full"
          rows={3}
          value={legalDisclaimer}
          onChange={(e) => setLegalDisclaimer(e.target.value)}
        />
      </div>

      {/* Details */}
      <div>
        <h2 className="text-sm font-bold py-1">Technical Details</h2>
        <ObjectInput value={details} setResult={setDetails} edit />
      </div>

      {/* Submit Button */}
      <div className="py-2">
        <button
          type="submit"
          className="bg-yellow-600 hover:bg-yellow-500 text-white px-2 py-1 rounded"
        >
          {edit ? 'Update Product': 'Create Product'}
        </button>
      </div>
    </form>
  );
}

export default NewCompanyProduct;
