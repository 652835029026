import React, { useState } from "react";
import Title from "./Title";

function AddRemoveElements() {
  const [elements, setElements] = useState([]);
  function removeElement(id) {
    const newElements = elements.filter((item) => item !== id);
    setElements(newElements);
  }
  function addElement() {
    
    const newElements = [...elements, getRandomUniqueId()];
    setElements(newElements);
  }
  function getRandomUniqueId() {
    let unique = new Date().valueOf();
    const uniqueNumber = String(unique).substring(1, 13);
    return uniqueNumber;
  }
  
  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Add or Remove Elements" id="title" />

      <div>
        <button
          id="addBtn"
          className="bg-blue-800 text-blue-100 py-2 px-3 rounded m-2 hover:bg-blue-700"
          onClick={addElement}
        >
          Add New Element
        </button>
      </div>
      <div >
        {elements.map((item) => (
          <button
            className="bg-red-800 text-red-100 py-2 px-3 rounded m-2 hover:bg-red-700"
            key={item}
            onClick={() => removeElement(item)}
            data-id={item}
          >
            Remove
          </button>
        ))}
      </div>
    </div>
  );
}

export default AddRemoveElements;
