import React from "react";

function Label({title, required, className }) {
  return (
    <label className={`font-bold ${className || ""}`}>
      {title} {required && <span className="text-red-600">*</span>}
    </label>
  );
}

export default Label;
