import React, { useContext, useState } from "react";
import PaymentContext from "../../../contexts/customer/payment/payment-context";
import Modal from "../../shared/utilities/Modal";
import NewPayment from "./NewPayment";
import PaymentImage from "./PaymentImage";
import PaymentTitle from "./PaymentTitle";

function Payment({ paymentIndex }) {
  const { paymentMethods,removePaymentMethod } = useContext(PaymentContext);
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="flex shadow bg-white p-4 rounded">
      <div className="w-8/12 px-2 py-4 flex items-center gap-2">
        <div className="w-4/12">
          <PaymentImage cardNumber={paymentMethods[paymentIndex].cardNumber} />
        </div>
        <div className="w-8/12 flex flex-col gap-2">
          <PaymentTitle className="font-bold text-xl" cardNumber={paymentMethods[paymentIndex].cardNumber} />
          <p>Debit Card Ending in .... {paymentMethods[paymentIndex].cardNumber.substr(-4)}</p>
          <div className="flex gap-2 py-2">
            <button className="text-blue-800 cursor-pointer hover:underline" onClick={() => setShowModal(true)}>
              Edit
            </button>
            <p> | </p>
            <button
              className="text-blue-800 cursor-pointer hover:underline"
              onClick={() => removePaymentMethod(paymentMethods[paymentIndex])}
            >
              remove
            </button>
          </div>
        </div>
      </div>
      
      <Modal show={showModal} setShow={setShowModal} options={{ closeButton: true }} className="top-10 left-1/2 -translate-x-1/2 w-1/2">
        <NewPayment setShow={setShowModal} payment={paymentMethods[paymentIndex]} edit={true}  />
      </Modal>
    </div>
  );
}

export default Payment;
