import React, { useState } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { toast } from "react-toastify";

import Modal from "../shared/utilities/Modal";
import Title from "./Title";
function Alert() {
  const [showModal, setShowModal] = useState(false);

  function submit() {
    toast.success("Photo deleted successfully", {
      position: "bottom-right",
      draggable: true,
    });
    setShowModal(false);
  }

  return (
    <div className="px-4 flex flex-col gap-4">
      <Title title="Alert" />
      <p>Please click below button to open alert box. </p>
      <div>
        <button className="rounded py-2 px-3 bg-blue-400" id="alertBtn" onClick={() => setShowModal(true)}>
          Delete Photo
        </button>
      </div>

      <Modal show={showModal} setShow={setShowModal} className="top-10 left-1/2 -translate-x-1/2 translate-y-1/2 w-72">
        <div className="p-4">
          <div className="flex justify-center py-4">
            <HiOutlineInformationCircle className="text-5xl" />
          </div>
          <p>Are you sure you want to delete this photo permenantly?</p>
          <div className="flex justify-center gap-4 py-4">
            <button id="confirmBtn" className="rounded py-2 px-3 bg-red-400" onClick={submit}>
              Yes, Delete
            </button>
            <button id="cancelBtn" className="rounded py-2 px-3 bg-gray-200" onClick={() => setShowModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Alert;
