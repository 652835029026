import React, { useState } from "react";

import SidebarContext from "./sidebar-context";
import api from "../../api";

function SidebarState({ children }) {
  const [categories, setCategories] = useState({});

  async function fetchCategories() {
      const result = await api.get("/category");
      setCategories(result.data);
  }

  return (
    <SidebarContext.Provider
      value={{ categories, setCategories, fetchCategories }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export default SidebarState;
