import React from "react";

function Navbar() {

  return (
    <nav class="-mt-4 bg-white px-2 py-2 sticky w-full z-20 top-0 border-b shadow-lg border-gray-200 ">
      <div class="flex flex-wrap justify-between items-center mx-auto">
        <a className="text-xl font-bold cursor-pointer" href="/">
          TEKSCHOOL
        </a>
        <div className="flex gap-8">
            <div className="hover:text-blue-700 cursor-pointer font-semibold">Home</div>
            <div className="hover:text-blue-700 cursor-pointer font-semibold">About</div>
            <div className="hover:text-blue-700 cursor-pointer font-semibold">Services</div>
            <div className="hover:text-blue-700 cursor-pointer font-semibold">Contact</div>
        </div>
        <div>
            <button className="primary-btn">Get Started</button>
        </div>
        {/* <div class="justify-between items-center w-full flex w-auto order-1" id="navbar-sticky">
          <div class="flex p-4 mt-4 bg-gray-50 y-100 space-x-8 mt-0 text-sm md:font-medium md:border-0 md:bg-white ">
            <div>
              <a
                href="#"
                class="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0"
                aria-current="page"
              >
                Home
              </a>
            </div>
            <div>
              <a
                href="#"
                class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
              >
                About
              </a>
            </div>
            <div>
              <a
                href="#"
                class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
              >
                Services
              </a>
            </div>
            <div>
              <a
                href="#"
                class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
              >
                Contact
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </nav>
  );
}

export default Navbar;
