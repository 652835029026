import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosInterceptorsSetup } from "../contexts/api";
import {toast} from 'react-toastify'
function AxiosInterceptorNavigate() {

  let navigate = useNavigate();
  useEffect(()=>{
    AxiosInterceptorsSetup({ navigate ,toast});
  },[]);
  return <></>;
}

export default AxiosInterceptorNavigate;
