import React, { useState } from "react";
import AddressContext from "./address-context";
import api from "../../api";
import { pick } from "../../../helper";
function AddressState({ children }) {
  const [addresses, setAddresses] = useState([]);

  // the id of selected address in checkout page
  const [selectedAddressId, setSelectedAddressId] = useState(-1);

  async function fetchAddresses() {
    const result = await api.get("/address");
    setAddresses(result.data);
    result.data?.length && setSelectedAddressId(result.data[0].id);
  }

  async function addAddress(address) {
    try {
      const result = await api.post("/address", address);

      setAddresses((addresss) => {
        return [result.data, ...addresss];
      });

      setSelectedAddressId(result.data.id);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  async function removeAddress(address) {
    try {
      await api.delete(`/address/${address.id}`);

      const items = addresses.filter((item) => item.id !== address.id);
      setAddresses(items);
      setCorrectSelectedAddress(address);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  async function updateAddress(address) {
    try {
      const addressId = address.id;
      const payload = pick(
        address,
        "country",
        "fullName",
        "phoneNumber",
        "street",
        "apartment",
        "city",
        "state",
        "zipCode"
      );
      
      payload.zipCode = address.zipCode + "";
      const result = await api.put(`/address/${addressId}`, payload);
      setAddresses((addresses) => {
        return addresses.map((item) => (item.id === result.data.id ? result.data : item));
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  function setCorrectSelectedAddress(address) {
    if (address.id !== selectedAddressId) return;

    // refer to payment state same method
    for (let i = 0; i < addresses.length; i++) {
      if (address.id !== addresses[i].id) {
        return setSelectedAddressId(addresses[i].id);
      }
    }

    setSelectedAddressId(-1);
  }

  function getAddressById(addressId) {
    return addresses.find((item) => item.id === addressId);
  }
  return (
    <AddressContext.Provider
      value={{
        selectedAddressId,
        removeAddress,
        setSelectedAddressId,
        addresses,
        setAddresses,
        fetchAddresses,
        addAddress,
        updateAddress,
        getAddressById,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
}

export default AddressState;
