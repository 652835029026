import React from "react";

function PaymentImage({ cardNumber , className}) {
  let imageAddress = "/images/master_card.jpg";
  const startNumber = Number(cardNumber.substr(0, 1));
  
  switch (startNumber) {
    case 3:
      imageAddress = "/images/american_express.png";
      break;
    case 4:
      imageAddress = "/images/visa.jpg";
      break;
    case 6:
      imageAddress = "/images/discover.png";
      break;
    default:
      imageAddress = "/images/master_card.png";
  }

  return <img src={imageAddress} className={`w-full ${className}`} alt="Master Card" />;
}

export default PaymentImage;
