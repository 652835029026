import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HiOutlineShoppingCart, HiCheckCircle } from "react-icons/hi";

import CartContext from "../../../contexts/customer/cart/cart-context";
import AuthContext from "../../../contexts/shared/auth/auth-context";
import Star from "../utilities/Star";
import RecentlyViewedItemsContext from "../../../contexts/customer/recently-viewed-items/recently-viewed-items-context";
import Image from "../utilities/Image";
import api from "../../../contexts/api";
import Reviews from "../../customer/order/review/Reviews";

const selectArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
function Product() {
  const { addRecentlyViewedItem } = useContext(RecentlyViewedItemsContext);
  const { addCartItem, cartItemIndex } = useContext(CartContext);

  let params = useParams();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const { user } = useContext(AuthContext);
  useEffect(() => {
    async function fetchData() {
      const result = await api.get(`/product/${params.id}`);
      result.data.about = result.data.about?.trim();
      result.data.about = result.data.about?.split("\n");
      // following line is needed if using phpmyadmin but not when using workbench
      // result.data.details = JSON.parse(result.data.details);

      setProduct(result.data);

      addRecentlyViewedItem({
        id: result.data.id,
        price: result.data.price,
        imageUrl: result.data.imageUrl,
        rating: result.data.rating,
        name: result.data.name,
      });
    }
    fetchData();
  }, []);

  function addToCartBtnClicked() {
    if (user && user.role === "customer") {
      const cartItem = {
        roleId: user.roleId,
        productId: product.id,
        productName: product.name,
        productImageUrl: product.imageUrl,
        price: product.price,
        quantity,
      };

      addCartItem(cartItem);
    } else {
      // Not Authorized
      // #to-do
    }
  }

  return (
    <>
      {product && (
        <div className="product">
          <div className="product__layout">
            <div className="product__layout-column">
              <Image src={product.imageUrl} alt={product.name} />
            </div>
            <div className="product__layout-column">
              <h1 className="product__name">{product.descriptiveName}</h1>

              <div className="product__about-wrapper">
                <Star rating={product.rating} />
                <hr />
                <br /> <br />
                <h2 className="product__about-title">About This item</h2>
                <div className="product__about">
                  <div dangerouslySetInnerHTML={{ __html: product.about }}>
                    {/* {product.about.map((item, index) => (
                      <li className="list-disc" key={index}>
                        {item}
                      </li>
                    ))} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="product__cart ">
              {/* Cart Item */}
              <div className="product__cart-layout">
                <p className="product__cart-exists">
                  {cartItemIndex(product.id) >= 0 && (
                    <>
                      <HiCheckCircle className="product__cart-tick" /> Item in The Cart
                    </>
                  )}
                </p>
                <p className="product__cart-price">${product.price}</p>
                <div className="product__cart-qty-wrapper">
                  <label className="product__cart-qty">QTY: </label>
                  <select value={quantity} onChange={(e) => setQuantity(e.target.value)} className="product__select">
                    {selectArray.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <button className="product__btn" id="addToCartBtn" onClick={addToCartBtnClicked}>
                  <span>Add to Cart</span> <HiOutlineShoppingCart />
                </button>
              </div>
            </div>
          </div>
          <div className="py-8">
            <div className="product__description ">
              <h2 className="product__subtitle">Product Description</h2>
              <p>{product.description}</p>
            </div>

            <h2 className="product__subtitle">Product Information</h2>

            <div className="product__layout">
              <div className="md:w-4/12">
                <p className="product__super-sub">Technical Details</p>
                <table className="product__table">
                  <thead></thead>
                  <tbody>
                    {product.details &&
                      Object.keys(product.details).map((key) => (
                        <tr key={key}>
                          <td className="product__table-key">{key}</td>
                          <td className="product__table-value">{product.details[key]}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="md:w-8/12 md:px-8">
                <h2 className="product__subtitle">Customer Reviews</h2>
                <Reviews productId={product.id} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Product;
