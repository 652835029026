import React from "react";
import { OrderStatuses } from "../../../constants";
function OrderStatus({ order }) {
  let element = "";
  switch (order.status) {
    case OrderStatuses.Shipping:
      element = (
        <div className="order__status-arriving">
          <span>Arriving</span> 
          <span className="order__status-date">
            {new Date(order.deliverDate).toLocaleDateString("en-us", {
              day: "numeric",
              year: "numeric",
              month: "short",
            })}
          </span>
        </div>
      );
      break;
    case OrderStatuses.Canceled:
      element = <p className="order__status-canceled">
        Cancelled
      </p>;
      break;
    case OrderStatuses.Delivered: 
    element = <p className="order__status-delivered">Delivered</p>
            break;
    default:
      element = "";
  }
  return <div className="order__status">{element}</div>;
}

export default OrderStatus;
