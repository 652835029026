import {
  ADD_ITEM,
  DELETE_ITEM,
  EDIT_ITEM,
  SET_ITEMS,
  RESET,
  TOGGLE_CHECK,
  CHECK_ALL,
  UNCHECK_ALL,
  PLACE_ORDER,
} from "./cart-actions";

const getTotalSelectedQuantity = (items) => {
  return items.reduce((acc, item) => acc + (item.checked ? +item.quantity : 0), 0);
};
const getTotalSelectedPrice = (items) => {
  return items.reduce((acc, item) => acc + (item.checked ? item.price * item.quantity : 0), 0);
};
const getTotalQuantity = (items) => {
  return items.reduce((acc, item) => acc + item.quantity, 0);
};
const getTotalPrice = (items) => {
  return items.reduce((acc, item) => acc + item.price * item.quantity, 0);
};

const getNewState = (previousState, newItems) => {
  return {
    ...previousState,
    totalQuantity: getTotalQuantity(newItems),
    totalPrice: getTotalPrice(newItems),
    totalSelectedQuantity: getTotalSelectedQuantity(newItems),
    totalSelectedPrice: getTotalSelectedPrice(newItems),
    items: newItems,
  };
};

const reducer = (state, action) => {
  let newItems;

  switch (action.type) {
    case ADD_ITEM:
      newItems = [action.payload, ...state.items];
      return getNewState(state, newItems);
    case DELETE_ITEM:
      newItems = state.items.filter((item) => item.id !== action.payload.id);
      return getNewState(state, newItems);      
    case EDIT_ITEM:
      newItems = state.items.map((item) =>
        item.id === action.payload.id ? { ...item, quantity: action.payload.newQuantity } : item
      );
      return getNewState(state, newItems);
    case TOGGLE_CHECK:
      newItems = state.items.map((item) =>
        item.id === action.payload.id ? { ...item, checked: !item.checked } : item
      );
      return getNewState(state, newItems);
    case CHECK_ALL:
      newItems = state.items.map((item) => ({ ...item, checked: true }));
      return getNewState(state, newItems);
    case UNCHECK_ALL:
      newItems = state.items.map((item) => ({ ...item, checked: false }));
      return getNewState(state, newItems);
    case SET_ITEMS:
      newItems = [...action.payload].map((item) => ({ ...item, checked: true }));
      return getNewState(state, newItems);
    case PLACE_ORDER: 
      newItems = state.items.filter(item=>!item.checked);
      return getNewState(state,newItems);
    case RESET:
      return {
        totalQuantity: 0,
        totalPrice: 0,
        items: [],
      };
    default:
  }
};

export default reducer;
