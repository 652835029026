import React , {useState} from "react";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";

function AddressDetail({ order }) {
    const [showAddress, setShowAddress] = useState(false);
  return (
    <div className="order__address">
      <button onClick={() => setShowAddress(!showAddress)} className="order__address-btn">
        {order.fullName} {showAddress ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />}
      </button>
      {showAddress && (
        <div className="order__address-dropdown" onMouseLeave={() => setShowAddress(false)}>
          <p className="order__address-name">{order.fullName}</p>
          <p className="uppercase">{order.street}</p>
          <p className="uppercase">
            {order.city} {order.state} {order.zipCode}
          </p>
          <p>{order.country}</p>
        </div>
      )}
    </div>
  );
}

export default AddressDetail;
