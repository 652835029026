import { OrderStatuses } from "../../../constants";
import { ADD_ITEM, CANCEL_ORDER, RETURN_ORDER, SET_ITEMS, TOGGLE_CHECK } from "./order-actions";

// add checked or not checked to products of an order 
// checked is for return page where user has selected to return item or not
function getCheckedItems(products) {
  // if there is more than one item in order set default to not checked
  // if there is one item default should be checked

  return products.map((item) => ({
    ...item,
    checked: products.length > 1 ? false : true,
  }));
  
}
function toggleProductCheck(products, id) {
  return products.map((item) => (item.id === id ? { ...item, checked: !item.checked } : item));
}

const reducer = (state, action) => {
  let newItems = [];
  switch (action.type) {
    case ADD_ITEM:
      const order = {...action.payload, products: getCheckedItems(action.payload.products)};
      return {
        ...state,
        items: [order, ...state.items],
      };
    case SET_ITEMS:
      newItems = action.payload.map((item) => ({ ...item, products: getCheckedItems(item.products)}));
      return {
        ...state,
        items: newItems,
      };
    case CANCEL_ORDER:
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.payload ? { ...item, status: OrderStatuses.Canceled } : item
        ),
      };
    case TOGGLE_CHECK:
      newItems = state.items.map((item) =>
        item.id === action.payload.order.id
          ? { ...item, products: toggleProductCheck(item.products, action.payload.product.id) }
          : item
      );
      return {
        ...state,
        items: newItems,
      };
    case RETURN_ORDER:
      // if all products are selected remove it from items 
      // if not just remove selected products from state
      newItems = state.items.map((item) =>
        item.id === action.payload.id
          ? {
              ...item,
              products: getCheckedItems(item.products.filter(sub => !sub.checked)),
            }
          : item
      )
      .filter(item=> item.products.length);
      return {
        ...state,
        items: newItems,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
