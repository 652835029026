import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../shared/nav/Footer";
import SecondNav from "./nav/SecondNav";
import TopNav from "./nav/TopNav";

function Container() {
  return (
    <div className="h-screen flex flex-col justify-between bg-gray-100">
      <div>
        <TopNav />
        <SecondNav />
        <div >
          <Outlet />
        </div>
      </div>
      <div>
        
        <Footer />
      </div>
    </div>
  );
}

export default Container;
