import React, { useEffect, useState } from "react";
import { HiPlus, HiMinus } from "react-icons/hi";

function ObjectInput({ value, setResult, edit }) {
  const [inputs, setInputs] = useState([
    {
      id: getUniqueId(),
      title: "",
      value: "",
    },
  ]);

  useEffect(() => {
    setResult(inputs);
  }, [inputs]);

  useEffect(() => {
    // I am not proud of this solution
    // #todo refactor
    if (!value || !edit || value instanceof Array) return;
    
    const newItems = [];

    Object.keys(value).forEach((key) => {
      const item = { id: getUniqueId(), title: key, value: value[key] };
      newItems.push(item);
    });
    
    setInputs(newItems);
  }, [value]);

  function addItem(index) {
    setInputs((inputs) => {
      const item = { id: getUniqueId(), title: "", value: "" };
      
      const newItems = [
        ...inputs.slice(0, index + 1),
        item,
        ...inputs.slice(index + 1),
      ];

      return newItems;
    });
  }
  function removeItem(index) {
    setInputs((inputs) => {
      const newItems = [...inputs];
      newItems.splice(index, 1);

      return newItems;
    });
  }

  function handleTitleChange(e) {
    const index = e.target.dataset.index;
    setInputs((inputs) => {
      const newItems = [...inputs];
      newItems[index].title = e.target.value;

      return newItems;
    });
  }
  function handleValueChange(e) {
    const index = e.target.dataset.index;
    setInputs((inputs) => {
      const newItems = [...inputs];
      newItems[index].value = e.target.value;

      return newItems;
    });
  }

  function getUniqueId() {
    return String(Date.now().toString(32) + Math.random().toString(16)).replace(
      /\./g,
      ""
    );
  }
  return (
    <div>
      <div className="flex gap-4">
        <div className="w-5/12 font-bold">Title</div>
        <div className="w-6/12 font-bold">Value</div>
      </div>
      {inputs.map((item, index) => (
        <div
          className="flex gap-4 py-4 relative object-input-container"
          key={item.id}
        >
          <input
            type={"text"}
            className="border border-gray-400 bg-gray-200 py-1 px-2 focus:outline-none w-6/12"
            onChange={handleTitleChange}
            value={item.title}
            data-index={index}
          />
          <input
            type={"text"}
            className="border bg-white py-1 px-2 focus:outline-none w-6/12"
            onChange={handleValueChange}
            defaultValue={item.value}
            data-index={index}
          />
          <div className="hidden justify-end w-1/12 absolute top-0 right-0 button-container gap-2">
            <button onClick={() => removeItem(index)} type="button">
              <HiMinus className="hover:text-red-600" />
            </button>
            <button onClick={() => addItem(index)} type="button">
              <HiPlus className="hover:text-green-600" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ObjectInput;
